/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import firebase from 'firebase/app';
import 'firebase/analytics';
// import { toast } from 'react-toastify';
import Navbar from '../../Components/Navbar/Navbar';
import Input from '../../Components/Input/Input';
import Button from '../../Components/Button/Button';
import Loading from '../../Components/Loading/Loading';
import { APP_NAME } from '../../Constants/Constants';

import {
  retrieve as childrenActionRetrieveToUpdate,
} from '../../Generated/actions/children/update';
import { list as gradeActionList } from '../../Generated/actions/grade/list';
import { list as paymentMethodsActionList } from '../../Generated/actions/paymentMethods/list';
import { create as paymentsActionCreate } from '../../Generated/actions/payments/create';
import { retrieve as voucherActionRetrieve } from '../../Generated/actions/vouchers/show';
import { retrieve as voucherShowConfig } from '../../Generated/actions/vouchers/config';
import { retrieve as accountActionRetrieve } from '../../Generated/actions/account/show';
import { list as plansActionList } from '../../Generated/actions/plans/list';
import { sendEvent } from '../../Generated/actions/event/create';
import { updateTags } from '../../Generated/utils/OneSignal';
// import { getSystemSettings } from '../../Utils/Helper/helpers';

import './UpgradePage.scss';

const UpgradePage = () => {
  // States

  const analytics = firebase.analytics();

  const History = useHistory();

  const dispatch = useDispatch();

  const isLoading = useSelector((state) => state.payments.create.loading);

  const location = useSelector((state) => state.router.location.pathname);

  const locationSearch = useSelector((state) => state.router.location.search);

  const childrenUpdateSelector = useSelector((state) => state.children.update);

  const gradesListSelector = useSelector((state) => state.grade.list);

  const paymentMethodsSelector = useSelector((state) => state.paymentMethods.list);

  const plansSelector = useSelector((state) => state.plans.list);

  const showVoucherConfigSelector = useSelector((state) => state.vouchers.config);

  const language = useSelector((state) => state.language.change);
  const langData = language.lang ? language.lang.data : {};

  const [profileToUpgrade, setProfileToUpgrade] = useState({});

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);

  const [selectedPlan, setSelectedPlan] = useState(null);

  const voucherSelector = useSelector((state) => state.vouchers.show);

  const [showSubmitButton, setShowSubmitButton] = useState(false);

  const [openDropdown, setOpenDropdown] = useState([]);

  const [paymentActive, setPaymentActive] = useState('false');

  // Effects
  useEffect(() => {
    document.title = `${langData.upgradeHeader} - ${APP_NAME}`;
  });

  useEffect(() => {
    // getSystemSettings('is_monetization_active', setPaymentActive);
  }, [setPaymentActive]);

  useEffect(() => {
    if (paymentActive === 'false') {
      History.push({
        pathname: '/account',
      });
    }
  }, [paymentActive, History]);

  useEffect(() => {
    if (profileToUpgrade.id) {
      dispatch(
        sendEvent({
          activity_type: 'OPEN_SCREEN',
          title: 'open_screen',
          screen_name: 'UpgradeChildAccount',
          is_admin: 'No',
          payload: {
            source: 'account_login',
            child_id: profileToUpgrade.id,
          }
        })
      );
    }
  }, [profileToUpgrade, dispatch]);

  // If profile to update is not already present in redux
  // get from server
  useEffect(() => {
    if (!childrenUpdateSelector.addToEdit) {
      const stringToSend = `users/${location.split('/').pop()}`;
      dispatch(childrenActionRetrieveToUpdate(stringToSend));
    }
  }, [childrenUpdateSelector, location, dispatch]);

  // Add profile to edit to local state
  useEffect(() => {
    if (childrenUpdateSelector.addToEdit) {
      setProfileToUpgrade(childrenUpdateSelector.addToEdit);
    }
  }, [childrenUpdateSelector.addToEdit]);

  // If grades is already in redux arrange for dropdown
  // otherwise call from server
  useEffect(() => {
    if (!gradesListSelector.retrieved) {
      return dispatch(gradeActionList());
    }
  }, [gradesListSelector.retrieved, dispatch]);

  // If list of payment methods is not present in redux
  // Call for api
  useEffect(() => {
    dispatch(paymentMethodsActionList());
  }, [dispatch]);

  // If list of plans subscription is not present in redux
  // Call for api
  useEffect(() => {
    dispatch(plansActionList());
  }, [dispatch]);

  useEffect(() => {
    if (plansSelector.retrieved) {
      plansSelector.retrieved.map((plan) => {
        if (plan.is_default) {
          setSelectedPlan(plan);
        }
        return plan;
      });
    }
  }, [plansSelector.retrieved, dispatch]);

  useEffect(() => {
    dispatch(accountActionRetrieve());
  }, [dispatch]);

  useEffect(() => {
    dispatch(voucherShowConfig());
  }, [dispatch]);

  useEffect(() => {
    voucherSelector.retrieved = null;
    voucherSelector.error = null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [History]);

  useEffect(() => {
    if ((voucherSelector.retrieved && voucherSelector.retrieved.paid_amount <= 0) || selectedPaymentMethod) {
      setShowSubmitButton(true);
    }

    if (voucherSelector.error && voucherSelector.error.length > 0) {
      setShowSubmitButton(false);
    }
  }, [selectedPaymentMethod, voucherSelector]);

  useEffect(() => {
    updateTags(location.split('/').pop(), { payment_status: 'upgrade_page_open' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Functions

  const useQuery = () => {
    return new URLSearchParams(locationSearch);
  };

  const query = useQuery();

  const getGradeName = (gradeId) => {
    if (gradeId && gradesListSelector.retrieved) {
      const filteredGrade = gradesListSelector.retrieved.filter((grade) => grade.id === gradeId);
      return filteredGrade[0].name;
    }
    return '';
  };

  const onContinueClick = () => {
    // if (voucherSelector.retrieved && voucherSelector.retrieved.paid_amount > 0) {
    if (selectedPaymentMethod && selectedPaymentMethod.payment_method === 'PULSA') {
      // if (voucherSelector.retrieved) {
      History.push({
        pathname: `/upgrade/${profileToUpgrade.id}/telkomsel`,
        state: { profileToUpgrade, selectedPaymentMethod, selectedPlan }
      });
      // } else {
      //   toast.error(langData.telkomselNeedVoucher);
      // }
      return '';
    }
    // }

    dispatch(paymentsActionCreate(
      {
        user_id: profileToUpgrade.id,
        payment_method_id: selectedPaymentMethod ? selectedPaymentMethod.id : '',
        voucher_code: profileToUpgrade.referral_code,
        plan_id: selectedPlan.id
      },
      History,
      analytics,
      profileToUpgrade
    ));

    analytics.logEvent('continue_payment_clicked', {
      user_id: profileToUpgrade.id,
      payment_method: selectedPaymentMethod ? selectedPaymentMethod.payment_channel : null,
      subscription_type: selectedPlan.name,
      voucher_code: profileToUpgrade.referral_code,
    });
  };

  const onChange = (name, value) => {
    const object = { ...profileToUpgrade, [name]: value };
    setProfileToUpgrade(object);

    if (name === 'referral_code' && value === '') {
      voucherSelector.retrieved = null;
      voucherSelector.error = null;
    }
  };

  const onCheckVoucherClick = (planId) => {
    dispatch(voucherActionRetrieve({
      voucher_code: profileToUpgrade.referral_code,
      plan_id: planId || selectedPlan.id
    }, language.lang.lang));

    dispatch(
      sendEvent({
        activity_type: 'ACCOUNT_LOGIN_ACTION',
        title: 'Account Login Action',
        type: 'VoucherAttempt',
        is_admin: 'No',
        payload: {
          source: 'account_login',
          user_id: profileToUpgrade.id,
          voucher_code: profileToUpgrade.referral_code,
          subscription_type: selectedPlan.name,
          selectedPlan
        },
        child_id: profileToUpgrade.id
      })
    );

    analytics.logEvent('voucher_attempt', {
      user_id: profileToUpgrade.id,
      voucher_code: profileToUpgrade.referral_code,
      subscription_type: selectedPlan.name
    });
  };

  const onSelectedPlan = (plan) => {
    setSelectedPlan(plan);
    if (profileToUpgrade.referral_code) {
      onCheckVoucherClick(plan.id);
    }

    dispatch(
      sendEvent({
        activity_type: 'ACCOUNT_LOGIN_ACTION',
        title: 'Account Login Action',
        type: 'SelectSubscriptionType',
        is_admin: 'No',
        payload: {
          source: 'account_login',
          plan
        },
        child_id: profileToUpgrade.id
      })
    );

    analytics.logEvent('subscription_selection', {
      user_id: profileToUpgrade.id,
      subscription_type: plan.name
    });
    updateTags(profileToUpgrade.id, { payment_status: 'subscription_selection' });
  };

  const onSelectedPaymentMethod = (paymentMethod) => {
    setSelectedPaymentMethod(paymentMethod);

    dispatch(
      sendEvent({
        activity_type: 'ACCOUNT_LOGIN_ACTION',
        title: 'Account Login Action',
        type: 'SelectPaymentMethod',
        is_admin: 'No',
        payload: {
          source: 'account_login',
          paymentMethod
        },
        child_id: profileToUpgrade.id
      })
    );

    analytics.logEvent('payment_method_selection', {
      user_id: profileToUpgrade.id,
      payment_method: paymentMethod.payment_channel
    });
    updateTags(profileToUpgrade.id, { payment_status: 'payment_method_selection' });
  };

  const togglePaymentMethod = (paymentMethod) => {
    if (openDropdown.includes(paymentMethod)) {
      setOpenDropdown(openDropdown.filter((index) => index !== paymentMethod));
    } else {
      setOpenDropdown([...openDropdown, paymentMethod]);
    }
  };

  // Render Functions
  const renderPaymentChannel = (paymentMethod) => {
    return (
      <>
        {openDropdown.includes(paymentMethod)
          && paymentMethodsSelector.retrieved[paymentMethod].map((paymentChannel) => (
            <div
              key={paymentChannel.id}
              className='upgrade-page-payment-method'
              onClick={() => onSelectedPaymentMethod(paymentChannel)}
            >
              {paymentChannel.image_url
                ? <img
                  src={`/payment_method/${paymentChannel.image_url}`}
                  alt=""
                  className='upgrade-page-payment-method-img'
                />
                : <span>{paymentChannel.payment_channel}</span>}
              {selectedPaymentMethod && selectedPaymentMethod.id === paymentChannel.id ? (
                <span className='upgrade-page-payment-method-toggle-active-outer'>
                  <span className='upgrade-page-payment-method-toggle-active' />
                </span>
              ) : (
                <span className='upgrade-page-payment-method-toggle' />
              )}
            </div>
          ))}
      </>
    );
  };

  const renderPreviewPaymentChannel = (paymentMethod, total) => {
    return (
      <>
        {paymentMethodsSelector.retrieved[paymentMethod].slice(0, total).map((paymentChannel) => (
          paymentChannel.image_url
            ? <img
              key={paymentChannel.payment_channel}
              src={`/payment_method/${paymentChannel.image_url}`}
              alt=""
              className='upgrade-page-payment-method-wrapper-item'
            />
            : <span key={paymentChannel.payment_channel}>{paymentChannel.payment_channel}</span>
        ))}
        {paymentMethodsSelector.retrieved[paymentMethod].length > total
          ? <span className='upgrade-page-payment-method-wrapper-item'>
            +{paymentMethodsSelector.retrieved[paymentMethod].length - total}</span> : ''}
      </>
    );
  };

  const renderPaymentMethod = () => {
    if (paymentMethodsSelector.retrieved) {
      let filteredPaymentMethod = Object.keys(paymentMethodsSelector.retrieved);

      if (voucherSelector.retrieved && voucherSelector.retrieved.paid_amount <= 0) {
        return;
      }

      if (profileToUpgrade.subscription
        && paymentMethodsSelector.retrieved
        && profileToUpgrade.subscription.is_free !== true) {
        if (profileToUpgrade.latest_payment
          && profileToUpgrade.latest_payment.payment_method !== 'PULSA') {
          // eslint-disable-next-line max-len
          filteredPaymentMethod = filteredPaymentMethod.filter((method) => method.payment_method !== 'PULSA');
        }
      }

      return (
        <div className='upgrade-page-payment' >
          <span className='body-content-heading' >{langData.paymentMethod}</span>
          {paymentMethodsSelector.retrieved && filteredPaymentMethod.map((paymentMethod) => (

            <div key={paymentMethod} className="parent">
              <div className='upgrade-page-payment-method'
                key={paymentMethod} onClick={() => togglePaymentMethod(paymentMethod)}>
                <span><b className={`${selectedPaymentMethod
                  && paymentMethod === selectedPaymentMethod.payment_method ? 'selected' : ''}`}>
                  {langData[paymentMethod]}</b></span>

                <div className="upgrade-page-payment-method-wrapper">
                  {openDropdown.includes(paymentMethod) ? '' : renderPreviewPaymentChannel(paymentMethod, 2)}
                  <div className='upgrade-page-payment-method-wrapper-svg'>
                    <svg
                      width="24"
                      height="24"
                      color={`${selectedPaymentMethod
                        && paymentMethod === selectedPaymentMethod.payment_method ? '#4FCCCE' : ''}`}
                      className={`${openDropdown.includes(paymentMethod) ? 'active' : ''}`}
                      fill="none" stroke="currentColor" strokeWidth="2">
                      {openDropdown.includes(paymentMethod)
                        ? <path d="M18 15 12 9 6 15"></path>
                        : <path d="M6 9L12 15 18 9"></path>}
                    </svg>
                  </div>
                </div>
              </div>
              {renderPaymentChannel(paymentMethod)}
            </div>

          ))}

        </div>
      );
    }
  };

  const renderDiscountLabel = (preview) => {
    return (
      <span>
        {preview.voucher.discount_percent > 0 && preview.voucher.discount_amount > 0
          ? `IDR ${preview.voucher.discount_amount.toLocaleString()} + 
            ${parseInt(preview.voucher.discount_percent, 10)}% `
          : (preview.voucher.discount_percent > 0
            ? `${parseInt(preview.voucher.discount_percent, 10)}%`
            : `IDR ${preview.voucher.discount_amount.toLocaleString()} `)
        }
        {langData.discount}
      </span>
    );
  };

  const renderDiscountPreview = (preview) => {
    return (
      <div>
        <div className='transaction-success-detail' >
          <span>{selectedPlan && selectedPlan.name} Plan</span>
          <span className='bold-text' >
            IDR {preview.amount_before.toLocaleString()}
          </span>
        </div>

        <div className='transaction-success-detail bottom' >
          {renderDiscountLabel(preview)}
          <span className='bold-text' >
            IDR {preview.discount_amount.toLocaleString()}
          </span>
        </div>

        <div className='transaction-success-detail' >
          <span>{langData.totalPayment}</span>
          <span className='bold-text' >
            IDR {preview.paid_amount.toLocaleString()}
          </span>
        </div>
      </div>
    );
  };

  const renderVoucherCode = () => {
    return (
      ((showVoucherConfigSelector.retrieved && showVoucherConfigSelector.retrieved.value === 'true')
        || query.get('coupon') === 'true')
      && <div className='upgrade-page-voucher' >
        <div className='upgrade-page-voucher-heading' >
          <span>{langData.voucherCode}</span>
        </div>
        <div className='upgrade-page-voucher-form' >
          <Input
            placeholder={langData.voucherCodePlaceholder}
            onChange={(e) => onChange('referral_code', e.target.value)}
            value={profileToUpgrade.referral_code || ''}
            isInvalid={voucherSelector.error}
            errorMessage={voucherSelector.error}
          />

          <Button
            block
            type={!profileToUpgrade.referral_code ? 'disabled' : ''}
            onClick={() => onCheckVoucherClick()}>{langData.checkVoucher}
          </Button>
        </div>

        {voucherSelector.retrieved && renderDiscountPreview(voucherSelector.retrieved)}
      </div>
    );
  };

  return (
    <div className='page-container' >
      {isLoading && <Loading />}
      <Navbar />
      <div className='body-content' >
        <span className='body-content-heading' >
          <i
            className='fal fa-arrow-left body-content-heading-back-arrow'
            onClick={() => History.goBack()}
          />
          {langData.upgradeHeader}
        </span>

        <div className='upgrade-page-top' >
          <span className='upgrade-page-top-name' >{profileToUpgrade.nick_name || ''}</span>
          <div className='upgrade-page-top-labels'>
            {profileToUpgrade.school_name}
            <span className='plan-card-labels-dot' />
            {getGradeName(profileToUpgrade.grade_id)}
          </div>
        </div>

        <div className='border-section'>&nbsp;</div>

        <div className='upgrade-page-subscription'>
          <div className='upgrade-page-subscription-heading'>
            <span>{langData.subscriptionType}</span>
          </div>

          <div className='upgrade-page-subscription-type'>
            {plansSelector.retrieved && plansSelector.retrieved.map((plan) => (
              plan.price > 0 ? (
                <div
                  key={plan.id}
                  className={selectedPlan && selectedPlan.id === plan.id
                    ? 'upgrade-page-subscription-type-box selected'
                    : 'upgrade-page-subscription-type-box'}
                  onClick={() => onSelectedPlan(plan)}>
                  <span>{plan.name}</span>
                </div>
              ) : ''
            ))}
          </div>

          {selectedPlan && <div className='upgrade-page-subscription-premium'>
            {plansSelector.retrieved && plansSelector.retrieved.map((plan) => (
              <div
                key={plan.id}
                className={`upgrade-page-subscription-premium-plan ${plan.color}
                ${selectedPlan.id !== plan.id ? 'd-none' : ''}`}>
                <div className={'heading'} >
                  <span>Pintar Plan</span>
                  <div>
                    <span className="strikeout">Rp {parseInt(plan.strikethrough_price, 10).toLocaleString()}</span>
                    <span>Rp {plan.price.toLocaleString()}</span>
                  </div>
                </div>
                <div className={'points'} >
                  <span className={'point'} >
                    <span className={'tick'}>
                      <svg width="11" height="9" viewBox="0 0 11 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M1.32007 4.072L3.91207 7.23999L9.96007 1.48"
                          strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    </span>
                    {langData.fullAccess}
                  </span>
                  <span className={'point'} >
                    <span className={'tick'}>
                      <svg width="11" height="9" viewBox="0 0 11 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M1.32007 4.072L3.91207 7.23999L9.96007 1.48"
                          strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    </span>
                    {langData.unlimited}
                  </span>
                  <span className={'point'} >
                    <span className={'tick'}>
                      <svg width="11" height="9" viewBox="0 0 11 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M1.32007 4.072L3.91207 7.23999L9.96007 1.48"
                          strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    </span>
                    {langData.saved}
                  </span>
                </div>
              </div>
            ))}
          </div>}
        </div>

        {((showVoucherConfigSelector.retrieved && showVoucherConfigSelector.retrieved.value === 'true')
          || query.get('coupon') === 'true')
          && <div className='border-section'>&nbsp;</div>}

        {renderVoucherCode()}

        {renderPaymentMethod()}

        <div className='upgrade-page-submit' >
          <Button
            block
            type={!showSubmitButton ? 'disabled' : ''}
            onClick={() => onContinueClick()}
          >
            {langData.continue}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default UpgradePage;
