import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Logo from '../../Assets/Images/Logo.png';
import './Navbar.scss';

const Navbar = (props) => {
  const History = useHistory();
  const language = useSelector((state) => state.language.change);
  const langData = language.lang ? language.lang.data : {};

  const {
    showLogout
  } = props;

  Navbar.defaultProps = {
    showLogout: true
  };

  const logout = () => {
    localStorage.removeItem('teacher');
    localStorage.removeItem('token');
    localStorage.removeItem('child');
    localStorage.removeItem('payload');
    History.push('/');
  };

  return (
    <div className='navbar' >
      <img src={Logo} alt="" className='navbar-logo' />
      {showLogout && <span className='navbar-logout' onClick={() => logout()} >{langData.logout}</span>}
    </div>
  );
};

export default Navbar;
