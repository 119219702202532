import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import './Input.scss';

const Input = (props) => {
  const {
    controlId,
    label,
    type,
    placeholder,
    isInvalid,
    errorMessage,
    onChange,
    disabled,
    addInputClass,
    style,
    onBlur,
    onFocus,
    value,
    autoComplete,
    autoFocus,
    iconLabel,
    customLabel,
    customModal,
    phoneNumber,
  } = props;

  const language = useSelector((state) => state.language.change);

  const langData = language.lang ? language.lang.data : {};

  const renderCustomLabel = (icon, iconName) => {
    return (
      <div className="form-input-custom-label">
        <div className="form-input-custom-label-wrapper-icon">
          <img src={icon} alt={iconName} />
        </div>
        {iconName}
      </div>
    );
  };

  const renderCustomModal = () => {
    return (
      <span className="alternative" onClick={() => customModal(true)}>{langData.loginButton}</span>
    );
  };

  return (
    <div style={style} className='form-input'>
      <Form.Group controlId={controlId} className={`${phoneNumber ? 'phone-number' : ''}`}>
        <Form.Label className='form-input-label'>{label}</Form.Label>
        {iconLabel ? renderCustomLabel(iconLabel, customLabel) : ''}
        <Form.Control
          className={`form-input-control ${addInputClass}`}
          type={type}
          placeholder={placeholder}
          isInvalid={isInvalid}
          onChange={onChange}
          disabled={disabled}
          onBlur={onBlur}
          onFocus={onFocus}
          value={value}
          autoComplete={autoComplete}
          autoFocus={autoFocus}
        />
        <Form.Control.Feedback type="invalid" className={`${errorMessage ? 'active' : ''}`}>
          {errorMessage} {customModal ? renderCustomModal() : ''}
        </Form.Control.Feedback>
      </Form.Group>
    </div>
  );
};

Input.propTypes = {
  controlId: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  addClass: PropTypes.string,
  style: PropTypes.object,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  autoFocus: PropTypes.bool,
  value: PropTypes.string,
  iconLabel: PropTypes.string,
  customLabel: PropTypes.string,
  customModal: PropTypes.func,
  phoneNumber: PropTypes.bool,
};

Input.defaultProps = {
  placeholder: '',
  required: false,
  isInvalid: false,
  errorMessage: '',
  disabled: false,
  value: '',
  autoFocus: false,
  iconLabel: null,
  customLabel: null,
  phoneNumber: false,
};

export default Input;
