import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import firebase from 'firebase/app';
import 'firebase/analytics';
import Button from '../../Components/Button/Button';
import Correct from '../../Assets/Images/correct.png';
import { APP_NAME } from '../../Constants/Constants';

import { retrieve as paymentsActionRetrieve } from '../../Generated/actions/payments/show';
import { updateTags } from '../../Generated/utils/OneSignal';
import { sendEvent } from '../../Generated/actions/event/create';

import './TransactionSuccess.scss';

const TransactionSuccess = () => {
  // States

  const analytics = firebase.analytics();

  const History = useHistory();

  const dispatch = useDispatch();

  const location = useSelector((state) => state.router.location.pathname);

  const paymentShowSelector = useSelector((state) => state.payments.show);

  const language = useSelector((state) => state.language.change);
  const langData = language.lang ? language.lang.data : {};

  // Effects
  useEffect(() => {
    document.title = `${langData.transactionSuccess} - ${APP_NAME}`;
  });

  useEffect(() => {
    const orderId = location.split('/').pop();
    dispatch(paymentsActionRetrieve(`payments/${orderId}`));
  }, [dispatch, location]);

  useEffect(() => {
    if (paymentShowSelector.retrieved) {
      if (moment(paymentShowSelector.retrieved.paid_at).isSame(moment(), 'day') && document.referrer !== '') {
        analytics.logEvent('invoice_paid', {
          user_id: paymentShowSelector.retrieved.user_id,
          order_id: paymentShowSelector.retrieved.order_id,
          payment_method: paymentShowSelector.retrieved.payment_method
        });

        updateTags(paymentShowSelector.retrieved.user_id, { payment_status: 'invoice_paid' });

        if (paymentShowSelector.retrieved.voucher) {
          analytics.logEvent('voucher_redeemed', {
            voucher_code: paymentShowSelector.retrieved.voucher.code
          });
        }
      }
    }
  }, [paymentShowSelector, analytics]);

  useEffect(() => {
    if (paymentShowSelector.retrieved) {
      if (paymentShowSelector.retrieved.user_id) {
        dispatch(
          sendEvent({
            activity_type: 'OPEN_SCREEN',
            title: 'open_screen',
            screen_name: 'Transaction Success Page',
            is_admin: 'No',
            payload: {
              source: 'account_login',
              child_id: paymentShowSelector.retrieved.user_id,
            }
          })
        );
      }
    }
  }, [paymentShowSelector, dispatch]);

  // Render Functions
  const renderTop = () => {
    return (
      <div className='transaction-success-top' >
        <div className='transaction-success-top-circle-white' />
        <span className='transaction-success-top-heading' >{langData.congrats}</span>
        <div className='transaction-success-top-tick-cover' >
          <div className='transaction-success-top-tick-main' >
            <img src={Correct} alt="" />
          </div>
        </div>
        <span className='transaction-success-top-sub' >{langData.transactionSuccess}</span>
        <div className='transaction-success-top-circle-dark' />

        {/* Plus icons */}
        <i className='fas fa-plus' id='one' />
        <i className='fas fa-plus' id='two' />

        {/* Star Icons */}
        <i className='fas fa-star' id='one' />
        <i className='fas fa-star' id='two' />

        {/* Empty Dots */}
        <i className='transaction-success-top-dots-empty' id='one' />
        <i className='transaction-success-top-dots-empty' id='two' />
        <i className='transaction-success-top-dots-empty' id='three' />
        <i className='transaction-success-top-dots-empty' id='four' />

        {/* Filled Dots */}
        <i className='transaction-success-top-dots' id='one' />
        <i className='transaction-success-top-dots' id='two' />
        <i className='transaction-success-top-dots' id='three' />
        <i className='transaction-success-top-dots' id='four' />
      </div>
    );
  };

  const renderDiscountLabel = (preview) => {
    return (
      <span>
        {preview.voucher.discount_percent > 0 ? `${parseInt(preview.voucher.discount_percent, 10)}% `
          : `Rp${preview.voucher.discount_amount.toLocaleString()} `}
        Discount
      </span>
    );
  };

  const renderBottom = () => {
    return (
      <div className='transaction-success-content' >

        {/* <div className='transaction-success-banner' >
          <span className='transaction-success-banner-dot left' />
          {langData.premiumFor} {paymentShowSelector.retrieved ? paymentShowSelector.retrieved.user.nick_name : ''}
          <span className='transaction-success-banner-dot right' />
        </div> */}

        <span className='transaction-success-details-heading' >{langData.transactionDetail}</span>

        <div className='transaction-success-detail' >
          <span>{langData.time}</span>
          <span className='bold-text' >
            {paymentShowSelector.retrieved
              ? moment(paymentShowSelector.retrieved.paid_at).format('DD MMMM YYYY, hh:mm A')
              : ''} (GMT+7)
          </span>
        </div>

        <div className='transaction-success-detail bottom' >
          <span>{langData.referenceId}</span>
          <span className='bold-text' >
            {paymentShowSelector.retrieved ? paymentShowSelector.retrieved.order_id : ''}
          </span>
        </div>

        <div className='transaction-success-detail' >
          <span>{langData.subscriptionType}</span>
          <span className='bold-text' >
            {paymentShowSelector.retrieved ? paymentShowSelector.retrieved.plan.name : ''}
          </span>
        </div>

        <div className='transaction-success-detail bottom' >
          <span>{langData.paymentMethod}</span>
          <span className='bold-text' >
            {paymentShowSelector.retrieved
              && paymentShowSelector.retrieved.payment_channel
              ? paymentShowSelector.retrieved.payment_channel.split('_').join(' ')
              : ''}
          </span>
        </div>

        {paymentShowSelector.retrieved && paymentShowSelector.retrieved.discount_amount > 0 && (
          <div className='transaction-success-detail' >
            {renderDiscountLabel(paymentShowSelector.retrieved)}
            <span className='bold-text' >Rp{paymentShowSelector.retrieved.discount_amount.toLocaleString()}</span>
          </div>
        )}

        <div className='transaction-success-detail' >
          <span>{langData.subscriptionAmount}</span>
          <span className='bold-text' >
            Rp{paymentShowSelector.retrieved ? paymentShowSelector.retrieved.amount_before.toLocaleString() : 0}
          </span>
        </div>

        {/* {paymentShowSelector.retrieved && paymentShowSelector.retrieved.voucher && (
        <div className='transaction-success-detail bottom' >
          <span>{langData.voucherCode}</span>
          <span className='bold-text' >{paymentShowSelector.retrieved.voucher.code}</span>
        </div>
        )} */}

        <div className='transaction-success-detail' >
          <span>{langData.totalPayment}</span>
          <span className='bold-text' >
            Rp{paymentShowSelector.retrieved ? paymentShowSelector.retrieved.paid_amount.toLocaleString() : 0}
          </span>
        </div>

        <div className='transaction-success-submit' >
          <Button block onClick={() => History.push('/account')} >Okay!</Button>
        </div>
      </div>
    );
  };

  return (
    <div className='page-container' >
      {renderTop()}
      {renderBottom()}
    </div>
  );
};

export default TransactionSuccess;
