/* eslint-disable consistent-return */
import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import Email from '../../Assets/Images/email.png';
import Button from '../../Components/Button/Button';
import Loading from '../../Components/Loading/Loading';
import { APP_NAME } from '../../Constants/Constants';
import { forgotPasswordSchema } from '../../Schemas/ForgotPasswordSchema';
import { requestForgotPassword } from '../../Actions/ForgotPassword';
import Lang from '../../Components/Lang/Lang';
import Header from '../../Components/Header/Header';

import './ForgotPassword.scss';

const ForgotPassword = () => {
  // States

  const History = useHistory();

  const dispatch = useDispatch();

  const isLoading = useSelector((state) => state.forgotPassword.isLoading);

  const language = useSelector((state) => state.language.change);
  const langData = language.lang ? language.lang.data : {};

  document.title = `${langData.passwordReset} - ${APP_NAME}`;

  const formik = useFormik({
    initialValues: {
      username: ''
    },
    validationSchema: forgotPasswordSchema(langData),
  });

  const {
    setFieldValue, errors,
    values, validateForm, setStatus,
  } = formik;

  const [userDetails, setUserDetails] = useState({
    username: '',
  });

  const [activeField, setActiveField] = useState('');

  // Functions

  const onChange = (name, value) => {
    const object = { ...userDetails, [name]: value };
    setUserDetails(object);
  };

  const handleForgotPassword = async () => {
    const validate = await validateForm();
    if (Object.keys(validate).length === 0) {
      return dispatch(requestForgotPassword(values, History, langData));
    }
    return setStatus(langData.emailNotCorrect);
  };

  const onLoginClick = () => {
    History.push('/');
  };

  const renderInputs = () => {
    return (
      <div className='login-page-inputs' >
        <div
          className={`login-page-input-container ${(activeField !== 'username' && errors.username) ? 'errored' : ''}`}
        >
          <img
            src={Email}
            alt=""
            className={`login-page-input-img ${(activeField !== 'username' && errors.username) ? 'errored' : ''}`}
          />
          <Form.Control
            type='text'
            placeholder='E-Mail'
            onChange={(e) => onChange('username', e.target.value)}
            bsPrefix='login-page-input'
            value={userDetails.username}
            onFocus={() => setActiveField('username')}
            onBlur={() => {
              setActiveField();
              setFieldValue('username', userDetails.username);
            }}
          />
        </div>
        {activeField !== 'username'
          && errors.username
          && <span className='login-page-validation-error' >{errors.username}</span>}

      </div>
    );
  };

  const renderHelperText = () => {
    return (
      <div className='login-page-helper-text' >
        <span className='login-page-helper-text-forgot' onClick={() => onLoginClick()} >{langData.backLogin}</span>
      </div>
    );
  };

  const renderSubmit = () => {
    return (
      <div className='login-page-submit' >
        <Button block onClick={() => handleForgotPassword()} >
          {langData.submit}
        </Button>
      </div>
    );
  };

  return (
    <div className='page-container login-page' >
      {isLoading && <Loading />}
      <Lang/>
      <Header
        text={langData.passwordReset}
        back={true}
      />
      {renderInputs()}
      {renderHelperText()}
      {renderSubmit()}
    </div>
  );
};

export default ForgotPassword;
