import React from 'react';
import PropTypes from 'prop-types';
import './Label.scss';

const Label = ({ type, children }) => {
  return (
    <span className={`label-container ${type}`}>
      {children}
    </span>
  );
};

Label.propTypes = {
  type: PropTypes.string,
};

// Accepted Types:
// PREMIUM
// STANDARD

export default Label;
