import React, { useEffect } from 'react';
import { Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
// eslint-disable-next-line import/no-extraneous-dependencies
import { createBrowserHistory } from 'history';
import { useDispatch } from 'react-redux';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import TokenRoute from './TokenRoute';
import ChildRoute from './ChildRoute';
import TeacherRoute from './TeacherRoute';
import OpenRoute from './OpenRoute';

// Pages Import
import LoginPage from '../Pages/LoginPage/LoginPage';
import LoginLink from '../Pages/LoginLink/LoginLink';
import LoginLinkLiveChat from '../Pages/LoginLinkLiveChat/LoginLinkLiveChat';
import AccountPage from '../Pages/AccountPage/AccountPage';
import EditChild from '../Pages/EditChild/EditChild';
import EditParent from '../Pages/EditParent/EditParent';
import AddChild from '../Pages/AddChild/AddChild';
import UpgradePage from '../Pages/UpgradePage/UpgradePage';
import BillingHistory from '../Pages/BillingHistory/BillingHistory';
import TransactionSuccess from '../Pages/TransactionSuccess/TransactionSuccess';
import ForgotPassword from '../Pages/ForgotPassword/ForgotPassword';
import Signup from '../Pages/Signup/Signup';
import { changeLanguage } from '../Generated/actions/language/change';
import TelkomselPayment from '../Pages/TelkomselPayment/TelkomselPayment';
import Subscription from '../Pages/Subscription/Subscription';
import VerificationPage from '../Pages/VerificationPage/VerificationPage';
import TeacherPage from '../Pages/TeacherPage/TeacherPage';
import LiveClassPage from '../Pages/LiveClassPage/LiveClassPage';
import DoubtClearingChatPage from '../Pages/DoubtClearingChatPage/DoubtClearingChatPage';
import ReportPage from '../Pages/ReportPage/ReportPage';
import CertificatePage from '../Pages/CertificatePage/CertificatePage';

const AppRouter = () => {
  const history = createBrowserHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (localStorage.getItem('language')) {
      dispatch(changeLanguage(localStorage.getItem('language')));
    } else {
      localStorage.setItem('language', 'id');
      dispatch(changeLanguage('id'));
    }
  }, [dispatch]);

  return (
    <ConnectedRouter history={history}>
      <Switch>
        <PublicRoute path='/' exact={true} component={LoginPage} />
        <TokenRoute path='/login-link/:token' exact={true} component={LoginLink} />
        <TokenRoute path='/login-link-live-chat/:token' exact={true} component={LoginLinkLiveChat} />
        <OpenRoute path='/report/:code' exact={true} component={ReportPage} />
        <OpenRoute path='/e-certificates/:code' exact={true} component={CertificatePage} />
        <PublicRoute path='/forgot-password' exact={true} component={ForgotPassword} />
        <PublicRoute path='/verification' exact={true} component={VerificationPage} />
        <PublicRoute path='/signup' exact={true} component={Signup} />
        <PrivateRoute path='/account' exact={true} component={AccountPage} />
        <TeacherRoute path='/teacher' exact={true} component={TeacherPage} />
        <TeacherRoute path='/live-class/:id' exact={true} component={LiveClassPage} />
        <ChildRoute path='/doubt-clearing-chat' exact={true} component={DoubtClearingChatPage} />
        <PrivateRoute path='/add-child' exact={true} component={AddChild} />
        <PrivateRoute path='/edit-child/:id' exact={true} component={EditChild} />
        <PrivateRoute path='/edit-parent/:id' exact={true} component={EditParent} />
        <PrivateRoute path='/upgrade/:id' exact={true} component={UpgradePage} />
        <PrivateRoute path='/subscription-status/:id' exact={true} component={Subscription} />
        <PrivateRoute path='/upgrade/:id/telkomsel' exact={true} component={TelkomselPayment} />
        <PrivateRoute path='/billing' exact={true} component={BillingHistory} />
        <PrivateRoute path='/transaction_success/:id' exact={true} component={TransactionSuccess} />
      </Switch>
    </ConnectedRouter>
  );
};

export default AppRouter;
