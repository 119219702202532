import { combineReducers } from 'redux';

export function addToEdit(state = null, action) {
  switch (action.type) {
    case 'CHILDREN_ADD_TO_EDIT':
      return action.data;
      
      case 'CHILDREN_ADD_TO_EDIT_RESET':
      return null;

    default:
      return state;  
  }
}

export function updateError(state = null, action) {
  switch (action.type) {
    case 'USER_UPDATE_UPDATE_ERROR':
      return action.updateError;

    case 'USER_UPDATE_RESET':
      return null;

    default:
      return state;
  }
}

export function updateLoading(state = false, action) {
  switch (action.type) {
    case 'USER_UPDATE_UPDATE_LOADING':
      return action.updateLoading;

    case 'USER_UPDATE_RESET':
      return false;

    default:
      return state;
  }
}

export function updated(state = null, action) {
  switch (action.type) {
    case 'USER_UPDATE_UPDATE_SUCCESS':
      return action.updated;

    case 'USER_UPDATE_RESET':
      return null;

    default:
      return state;
  }
}

export default combineReducers({
  updateError,
  updateLoading,
  updated,
  addToEdit,
});
