/* eslint-disable consistent-return */
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import firebase from 'firebase/app';
import 'firebase/analytics';
import Logo from '../../Assets/Images/Logo.png';
import { signInForLiveChat } from '../../Actions/Auth';
import { APP_NAME } from '../../Constants/Constants';

import './LoginLinkLiveChat.scss';

const LoginLinkLiveChat = () => {
  // States

  const analytics = firebase.analytics();

  const History = useHistory();

  const dispatch = useDispatch();

  const location = useSelector((state) => state.router.location.pathname);

  const language = useSelector((state) => state.language.change);
  const langData = language.lang ? language.lang.data : {};

  // Functions

  useEffect(() => {
    document.title = `Login you in - ${APP_NAME}`;
  });

  useEffect(() => {
    localStorage.removeItem('token');
    localStorage.removeItem('teacher');
    localStorage.setItem('child', true);
    const handleLogin = async () => {
      const values = {
        token: location.split('/').pop()
      };

      if (values.token.length > 0) {
        return dispatch(signInForLiveChat(values, History, langData));
      }
    };
    handleLogin();
    const token = location.split('/').pop();
    const userId = token.split(':').shift();
    analytics.logEvent('one_time_login_open', { user_id: userId });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Render Functions
  const renderHeader = () => {
    return (
      <div className='login-page-header' >
        <span className='login-page-header-heading' >{langData.pleaseWait}...</span>
        <img className='login-page-header-img' src={Logo} alt="logo" />
      </div>
    );
  };

  return (
    <div className='page-container login-page' >
      {renderHeader()}
    </div>
  );
};

export default LoginLinkLiveChat;
