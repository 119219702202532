/* eslint-disable array-callback-return */
/* eslint-disable no-use-before-define */
/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import Navbar from '../../Components/Navbar/Navbar';
import Input from '../../Components/Input/Input';
import User from '../../Assets/Images/user.png';
import School from '../../Assets/Images/school.png';
import DropdownFormComponent from '../../Components/DropdownFormComponent/DropdownFormComponent';
import Button from '../../Components/Button/Button';
import Loading from '../../Components/Loading/Loading';
import { sendEvent } from '../../Generated/actions/event/create';
import { APP_NAME } from '../../Constants/Constants';

import { list as gradeActionList } from '../../Generated/actions/grade/list';
import {
  retrieve as childrenActionRetrieveToUpdate,
  update as childrenActionUpdate,
} from '../../Generated/actions/children/update';

import './EditChild.scss';

const EditChild = () => {
  // States

  const History = useHistory();

  const dispatch = useDispatch();

  const childrenUpdateSelector = useSelector((state) => state.children.update);

  const gradesListSelector = useSelector((state) => state.grade.list);

  const location = useSelector((state) => state.router.location.pathname);

  const isLoading = useSelector((state) => state.children.update.updateLoading);

  const language = useSelector((state) => state.language.change);
  const langData = language.lang ? language.lang.data : {};

  const [profileToEdit, setProfileToEdit] = useState({});

  const [isSaveDisabled, setIsSaveDisabled] = useState(true);

  // Grades List for react-select component
  const [arrangedGradesList, setArrangedGradesList] = useState([]);

  const genderOptions = [
    { label: langData.boy, value: 'boy' },
    { label: langData.girl, value: 'girl' },
  ];

  // Effects
  useEffect(() => {
    document.title = `${langData.editChildHeader} - ${APP_NAME}`;
  });

  useEffect(() => {
    if (profileToEdit.id) {
      dispatch(
        sendEvent({
          activity_type: 'OPEN_SCREEN',
          title: 'open_screen',
          screen_name: 'EditChildProfile',
          is_admin: 'No',
          payload: {
            source: 'account_login',
            child_id: profileToEdit.id
          }
        })
      );
    }
  }, [profileToEdit, dispatch]);

  // If profile to update is not already present in redux
  // get from server
  useEffect(() => {
    if (!childrenUpdateSelector.addToEdit) {
      const stringToSend = `users/${location.split('/').pop()}`;
      dispatch(childrenActionRetrieveToUpdate(stringToSend));
    }
  }, [childrenUpdateSelector, location, dispatch]);

  // Add profile to edit to local state
  useEffect(() => {
    if (childrenUpdateSelector.addToEdit) {
      const a = childrenUpdateSelector.addToEdit;
      const object = {
        id: a.id,
        nick_name: a.nick_name,
        username: a.username,
        gender: a.gender,
        school_name: a.school_name,
        grade_id: a.grade_id,
        grade: a.grade
      };
      setProfileToEdit(object);
    }
  }, [childrenUpdateSelector.addToEdit]);

  // If grades is already in redux arrange for dropdown
  // otherwise call from server
  useEffect(() => {
    if (!gradesListSelector.retrieved) {
      return dispatch(gradeActionList());
    }
    arrangeGrades(gradesListSelector.retrieved);
  }, [gradesListSelector.retrieved, dispatch]);

  // Functions

  const onChange = (name, value) => {
    if (isSaveDisabled) {
      setIsSaveDisabled(false);
    }
    const object = { ...profileToEdit, [name]: value };
    setProfileToEdit(object);
  };

  const arrangeGrades = (array) => {
    const arrangedArray = [];
    if (array.length > 0) {
      array.map((grade) => {
        const object = {
          label: grade.name,
          value: grade.id,
          sorting_order: grade.sorting_order
        };
        arrangedArray.push(object);
      });
    }
    setArrangedGradesList(arrangedArray);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const stringToSend = `users/${location.split('/').pop()}`;
    dispatch(childrenActionUpdate(stringToSend, profileToEdit, History, langData));
  };

  const onUpgradeClick = (account) => {
    History.push(`/upgrade/${account.id}`);
  };

  // Render Functions

  const renderSubmit = () => {
    return (
      <div className='edit-profile-submit' >
        <Button onClick={onSubmit} block type={isSaveDisabled ? 'disabled' : 'primary'} >
          {langData.saveChanges}
        </Button>
      </div>
    );
  };

  const getAccountType = (subscription) => {
    if (subscription) {
      if (subscription.is_free) {
        return langData.standard;
      }
      if (subscription.name === '3 Days' || subscription.name === 'Per 3 Hari') {
        return langData.threedays;
      }
      if (subscription.name === 'Weekly' || subscription.name === 'Per Minggu') {
        return langData.weekly;
      }
      if (subscription.name === 'Monthly' || subscription.name === 'Per Bulan') {
        return langData.monthly;
      }
      if (subscription.name === 'Yearly' || subscription.name === 'Per Tahun') {
        return langData.yearly;
      }
      return langData.standard;
    }
  };

  return (
    <div className='page-container' >
      {isLoading && <Loading />}
      <Navbar />
      <div className='body-content' >
        <div className='body-content-heading' >
          <i
            className='fal fa-arrow-left body-content-heading-back-arrow'
            onClick={() => History.goBack()}
          />
          {langData.editChildHeader}
        </div>

        <span className='edit-profile-input-label' >
          <img src={User} className='edit-profile-input-label-img' alt='' />
          {langData.nameLabel}
        </span>

        <div className='edit-profile-input' >
          <Input
            placeholder={langData.nameChildPlaceholder}
            value={profileToEdit.nick_name}
            onChange={(e) => onChange('nick_name', e.target.value)}
          />
        </div>

        <div className='edit-profile-input' >
          <Input
            placeholder={langData.usernamePlaceholder}
            onChange={(e) => onChange('username', e.target.value)}
            value={profileToEdit.username}
          />
        </div>

        <div className='edit-profile-input' >
          <DropdownFormComponent
            placeholder={langData.genderChildPlaceholder}
            options={genderOptions}
            onChange={(value) => onChange('gender', value)}
            value={profileToEdit.gender}
          />
        </div>

        <span className='edit-profile-input-label' >
          <img src={School} alt="" className='edit-profile-input-label-img' />
          {langData.schoolPlaceholder}
        </span>

        <div className='edit-profile-input' >
          <Input
            placeholder={langData.schoolChildPlaceholder}
            value={profileToEdit.school_name || ''}
            onChange={(e) => onChange('school_name', e.target.value)}
          />
        </div>

        <div className='edit-profile-input' >
          <DropdownFormComponent
            placeholder={langData.gradeChildPlaceholder}
            options={arrangedGradesList}
            value={profileToEdit.grade_id}
            onChange={(value) => {
              if (isSaveDisabled) {
                setIsSaveDisabled(false);
              }
              const filteredGradeObject = gradesListSelector.retrieved.filter((grade) => grade.id === value);
              const object = { ...profileToEdit, grade_id: value, grade: filteredGradeObject };
              setProfileToEdit(object);
            }}
          />
        </div>
        {childrenUpdateSelector.addToEdit && childrenUpdateSelector.addToEdit.subscription && (
          childrenUpdateSelector.addToEdit.subscription.is_free ? (
            <div className='edit-profile-banner grey' >
              <span className='edit-profile-banner-heading' >{langData.freePackage}</span>
              <span className='edit-profile-banner-sub' >{langData.standard}</span>
              <div className='edit-profile-banner-btn' >
                <Button onClick={() => onUpgradeClick(childrenUpdateSelector.addToEdit)} >{langData.upgrade}</Button>
              </div>
            </div>
          ) : (
            <div className={`edit-profile-banner ${getAccountType(childrenUpdateSelector.addToEdit.subscription)}`} >
              <span className='edit-profile-banner-heading' >
                {getAccountType(childrenUpdateSelector.addToEdit.subscription)}
              </span>
              <span className='edit-profile-banner-sub' >
                {langData.nextBillingDate}:{' '}
                {childrenUpdateSelector.addToEdit
                  ? moment(childrenUpdateSelector.addToEdit.subscription.ends_at)
                    .add(1, 'days').format('DD MMMM YYYY')
                  : ''}
              </span>
            </div>
          )
        )}

        {renderSubmit()}
      </div>
    </div>
  );
};

export default EditChild;
