import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import { Helmet } from 'react-helmet';
import firebase from 'firebase';
import Store from './Store/Store';
import * as serviceWorker from './serviceWorker';
import AppRouter from './Routers/AppRouter';
import {
  API_BASE_URL, GTAG_ID,
  FIREBASE_API_KEY, FIREBASE_APP_ID, FIREBASE_AUTH_DOMAIN, FIREBASE_DATABASE_URL,
  FIREBASE_MEASUREMENT_ID, FIREBASE_MESSAGING_SENDER_ID, FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET, API_KEY
} from './Constants/Constants';

import 'react-toastify/dist/ReactToastify.css';
import './index.scss';

const store = Store();

axios.defaults.baseURL = API_BASE_URL;
axios.defaults.headers['Content-Type'] = 'application/ld+json';

axios.interceptors.request.use(
  (config) => {
    if (!config.headers.Authorization) {
      const token = localStorage.getItem('token');
      if (token) {
        // eslint-disable-next-line no-param-reassign
        config.headers.Authorization = `Bearer ${token}`;
      }
    }
    if (config.url.includes(API_BASE_URL)) {
    // eslint-disable-next-line no-param-reassign
      config.headers['account-login-api-key'] = API_KEY;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

if (localStorage.getItem('imitating_email')) {
  axios.defaults.headers.common.imitate = localStorage.getItem('imitating_email');
}

// Firebase
firebase.initializeApp({
  apiKey: FIREBASE_API_KEY,
  authDomain: FIREBASE_AUTH_DOMAIN,
  databaseURL: FIREBASE_DATABASE_URL,
  projectId: FIREBASE_PROJECT_ID,
  storageBucket: FIREBASE_STORAGE_BUCKET,
  messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
  appId: FIREBASE_APP_ID,
  measurementId: FIREBASE_MEASUREMENT_ID
});
firebase.analytics();

ReactDOM.render(
  // <ToastContainer position='top-right' >
  <Provider store={store}>
    <ToastContainer position="top-right" />
    <AppRouter />
    <Helmet>
      <script async src={`https://www.googletagmanager.com/gtag/js?id=${GTAG_ID}`}></script>
      <script type="text/javascript">{`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${GTAG_ID}');
      `}</script>
    </Helmet>
  </Provider>,
  // </ToastContainer>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
