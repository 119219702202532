import { fetch } from '../../utils/dataAccess';
import { toast } from 'react-toastify';
import { updateTags } from '../../../Generated/utils/OneSignal';

export function error(error) {
  return { type: 'PAYMENTS_CREATE_ERROR', error };
}

export function loading(loading) {
  return { type: 'PAYMENTS_CREATE_LOADING', loading };
}

export function success(created) {
  toast.success(created.message);
  return { type: 'PAYMENTS_CREATE_SUCCESS', created };
}

export function unSubscribe(values, History) {
  return dispatch => {
    dispatch(loading(true));

    return fetch('payments/unsubscribe', { method: 'post', body: JSON.stringify(values) })
      .then(response => {
        dispatch(loading(false));

        return response.json();
      })
      .then(retrieved => {
        dispatch(success(retrieved));
        History.push(`/account`);
      })
      .catch(e => {
        dispatch(loading(false));
        toast.error(e.toString());
        dispatch(error(e.message));
      })
  }
}

export function verifyOTP(values, History, setOtpError) {
  return dispatch => {
    dispatch(loading(true));

    return fetch('payments/verify_otp', { method: 'post', body: JSON.stringify(values) })
      .then(response => {
        dispatch(loading(false));

        return response.json();
      })
      .then(retrieved => {
        if (retrieved.data.link) {
          window.open(retrieved.data.link);
          History.push(`/account`)
        } else {
          History.push(`/transaction_success/${retrieved.data.order_id}`)
        }
        dispatch(success(retrieved));
      })
      .catch(e => {
        dispatch(loading(false));
        toast.error(e.toString());
        dispatch(error(e.message));
        const response = JSON.parse(e.message);
        setOtpError(response[0]);
      })
  }
}

export function pulsaPayment(values, History, analytics, setStep) {
  return dispatch => {
    dispatch(loading(true));

    return fetch('payments/get_link', { method: 'post', body: JSON.stringify(values) })
      .then(response => {
        dispatch(loading(false));

        return response.json();
      })
      .then(retrieved => {
        setStep('otp');
        dispatch(success(retrieved));
      })
      .catch(e => {
        dispatch(loading(false));
        toast.error(e.toString());
        dispatch(error(e.message));
      });
  };
}

export function create(values, History, analytics, profileToUpgrade) {
  return dispatch => {
    dispatch(loading(true));

    return fetch('payments/get_link', { method: 'post', body: JSON.stringify(values) })
      .then(response => {
        dispatch(loading(false));

        return response.json();
      })
      .then(retrieved => {
        if (retrieved.data.link) {
          analytics.logEvent('invoice_created', {
            user_id: values.user_id
          });
          updateTags(values.user_id, { payment_status: 'invoice_created' });

          window.open(retrieved.data.link);
          History.push(`/account`)
        } else {
          History.push(`/transaction_success/${retrieved.data.order_id}`)
        }
        dispatch(success(retrieved));
      })
      .catch(e => {
        dispatch(loading(false));
        toast.error(e.toString());
        console.log(e.toString());
        if (e.toString() === "Error: Butuh email untuk menyelesaikan transaksi Anda!") {
          setTimeout(function () {
            History.push(`/edit-parent/${profileToUpgrade.parent_id}`);
          }, 1000);
        }
        dispatch(error(e.message));
      });
  };
}

export function reset() {
  return dispatch => {
    dispatch(loading(false));
    dispatch(error(null));
  };
}
