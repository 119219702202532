import { fetch } from '../../utils/dataAccess';

export function initiate(values) {
    return () => {
        return fetch('doubt_clearing/initiate', { method: 'post', body: JSON.stringify(values) })
            .then(response => {
                return response.json();
            })
    };
}

export function teacherResponse(values) {
    return () => {
        return fetch('doubt_clearing/teacher_response', { method: 'post', body: JSON.stringify(values) })
            .then(response => {
                return response.json();
            })
    };
}