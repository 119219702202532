import React from 'react';

import ModalHeader from '../../Assets/Images/modal-header.png';
import './Modal.scss';

const Modal = (props) => {
  return (
      <div className="confirm-modal" onClick={() => props.setModal(false)}>
        <div className="confirm-modal-content">
            <img src={ModalHeader} className="confirm-modal-content-logo" alt="confirm-modal-content-logo" />
            {props.children}
        </div>
      </div>
  );
};

export default Modal;
