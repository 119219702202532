import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { decryptData } from '../Utils/Helper/helpers';

export const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} component={(props) => (
    decryptData(localStorage.getItem('payload')) && decryptData(localStorage.getItem('payload')).role === 'parent'
      ? (<Component {...props} />)
      : (<Redirect to="/" />)
  )} />
);

export default PrivateRoute;
