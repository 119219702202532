/* eslint-disable consistent-return */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import firebase from 'firebase/app';
import 'firebase/analytics';
import Reaptcha from 'reaptcha';
import Button from '../../Components/Button/Button';
import Header from '../../Components/Header/Header';
import Input from '../../Components/Input/Input';
import Loading from '../../Components/Loading/Loading';
import { signUpOTP, verifySignUpOTP, errorMessage as setErrorMessage } from '../../Actions/Auth';
import { APP_NAME, CAPTCHA_KEY } from '../../Constants/Constants';
import Email from '../../Assets/Images/email.png';
import Phone from '../../Assets/Images/phone.png';
import Lock from '../../Assets/Images/signup_lock.png';
import { validateEmail } from '../../Utils/Helper/helpers';

import './VerificationPage.scss';

const VerificationPage = () => {
  // States

  const analytics = firebase.analytics();

  const History = useHistory();

  const dispatch = useDispatch();

  const isLoading = useSelector((state) => state.auth.isLoading);
  const errorMessage = useSelector((state) => state.auth.errorMessage);
  const language = useSelector((state) => state.language.change);

  const langData = language.lang ? language.lang.data : {};

  document.title = `${langData.loginButton} - ${APP_NAME}`;

  const [step, setStep] = useState('phone_number');
  const [login, setLogin] = useState(false);
  const [verify, setVerify] = useState(false);
  const [countdown, setCountdown] = useState();
  const [verifyCaptcha, setVerifyCaptcha] = useState(false);

  const [userDetails, setUserDetails] = useState({
    otp: '',
    otp_method: 'email',
  });

  useEffect(() => {
    dispatch(setErrorMessage(''));
  }, [dispatch]);

  useEffect(() => {
    if (!step.includes('verification') && userDetails.otp_method && userDetails.otp_method.includes('email')) {
      setStep('email');
    }
    if (!step.includes('verification') && userDetails.otp_method &&
      (userDetails.otp_method.includes('whatsapp') || userDetails.otp_method.includes('sms'))) {
      setStep('phone_number');
    }
  }, [userDetails, step]);

  useEffect(() => {
    if (step.includes('verification') && countdown > 0) {
      const intervalId = setInterval(() => {
        setCountdown(countdown - 1);
      }, 1000);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [countdown, step]);

  const onChange = (name, value) => {
    const object = { ...userDetails, [name]: value };
    setUserDetails(object);
  };

  const askVerify = async () => {
    if (userDetails.phone_number) {
      analytics.logEvent('ask_otp_signup', { phone_number: userDetails.phone_number });
    }
    if (userDetails.email) {
      analytics.logEvent('ask_otp_signup', { email: userDetails.email });
    }

    return dispatch(signUpOTP(userDetails, setStep, setCountdown));
  };

  const handleLogin = async () => {
    if (userDetails.phone_number) {
      analytics.logEvent('attempt_otp_signup', { phone_number: userDetails.phone_number });
    }
    if (userDetails.email) {
      analytics.logEvent('attempt_otp_signup', { email: userDetails.email });
    }

    return dispatch(verifySignUpOTP(userDetails, History));
  };

  const handleVerification = () => {
    History.push('/');
  };

  const setThePhoneNumber = (value) => {
    // Remove +62 from users input
    onChange('phone_number', value);
    if (value.length >= 10) {
      setVerify(true);
    } else {
      setVerify(false);
    }
  };

  const setTheEmail = (value) => {
    onChange('email', value);
    if (validateEmail(value)) {
      setVerify(true);
    } else {
      setVerify(false);
    }
  };

  const setTheOTP = (value) => {
    onChange('otp', value);
    if (value.length === 6) {
      setLogin(true);
    } else {
      setLogin(false);
    }
  };

  const setMethod = (value) => {
    onChange('otp_method', value);
  };

  const onVerifyCaptcha = () => {
    setVerifyCaptcha(true);
  };

  const renderField = () => {
    return (
      <div className="renderField">
        {step.includes('phone_number') ? (
          <Input
            iconLabel={Phone}
            customLabel={langData.paymentPhoneNumber}
            placeholder={langData.paymentField}
            onChange={(e) => setThePhoneNumber(e.target.value)}
            value={userDetails.phone_number}
            errorMessage={errorMessage || ''}
          />
        ) : ''}
        {step.includes('email') ? (
          <Input
            iconLabel={Email}
            customLabel='Email'
            placeholder='Masukan email Anda'
            onChange={(e) => setTheEmail(e.target.value)}
            value={userDetails.email}
            errorMessage={errorMessage || ''}
          />
        ) : ''}
        {step.includes('verification') ? (
          <Input
            iconLabel={Lock}
            customLabel={langData.paymentOTP}
            placeholder={langData.paymentOTPField}
            onChange={(e) => setTheOTP(e.target.value)}
            value={userDetails.otp}
            errorMessage={errorMessage || ''}
          />
        ) : ''}

        {(step.includes('phone_number') || step.includes('email'))
        && <Reaptcha sitekey={CAPTCHA_KEY} onVerify={onVerifyCaptcha} />}
        <br />
        {step.includes('phone_number') || step.includes('email') ? (
          <div>
            <label className="container-radio">
              Email
              <input
                type="radio"
                name="otp_method"
                checked={userDetails.otp_method === 'email'}
                onClick={() => setMethod('email')}
              />
              <span class="checkmark"></span>
            </label>
            <label className="container-radio">
              WhatsApp
              <input
                type="radio"
                name="otp_method"
                checked={userDetails.otp_method === 'whatsapp'}
                onClick={() => setMethod('whatsapp')}
              />
              <span class="checkmark"></span>
            </label>
            <label className="container-radio">
              SMS
              <input
                type="radio"
                name="otp_method"
                checked={userDetails.otp_method === 'sms'}
                onClick={() => setMethod('sms')}
              />
              <span class="checkmark"></span>
            </label>
          </div>
        ) : (
          ''
        )}
        <br />
        <div className="verifyField">
          <span>{(step.includes('phone_number') || step.includes('email'))
            ? langData.clickButton : langData.resendOTP}</span>
          {(step.includes('phone_number') || step.includes('email')) ? (
            <Button block onClick={() => askVerify()} type={verify && verifyCaptcha ? '' : 'disabled'}>
              {langData.paymentVerification}
            </Button>
          ) : (
            <Button block onClick={() => askVerify()} type={!countdown ? '' : 'disabled'}>
              {countdown > 1 ? `${countdown} ${langData.second}` : langData.resend}
            </Button>
          )}
        </div>
      </div>
    );
  };

  const renderSubmit = () => {
    return (
      <div className="login-page-submit">
        <Button block onClick={() => handleLogin()} type={login ? '' : 'disabled'}>
          {langData.signUpHere}
        </Button>
        <p className="paragraph">
          {langData.helpSignIn}{' '}
          <span style={{ cursor: 'pointer' }} onClick={() => handleVerification()}>
            {langData.signInHere}
          </span>
        </p>
      </div>
    );
  };

  return (
    <div className="page-container login-page">
      {isLoading && <Loading />}
      <Header />
      <p className="paragraph">{langData.firstPage}</p>
      {renderField()}
      {renderSubmit()}
    </div>
  );
};

export default VerificationPage;
