import React from 'react';
import PropTypes from 'prop-types';
import './EditButton.scss';

const EditButton = ({ onClick, disabled }) => {
  return (
    <span className={`edit-btn ${disabled ? 'disabled' : ''}`} onClick={onClick} >
      <i className='fas fa-pencil-alt' />
    </span>
  );
};

EditButton.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default EditButton;
