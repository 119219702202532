/* eslint-disable max-len */
import React, { createRef, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import Downloader from '../../Components/Downloader/Downloader';

import './ReportPage.scss';
import page1Background from '../../Assets/Images/blank_1.png';
import page2BackgroundFirst from '../../Assets/Images/blank_2_1.png';
import page2BackgroundMiddle from '../../Assets/Images/blank_2_2.png';
import page2BackgroundLast from '../../Assets/Images/blank_2_3.png';

import AvatarXav from '../../Assets/Images/xav.png';
import AvatarSitta from '../../Assets/Images/sitta.png';
import AvatarChaca from '../../Assets/Images/chacha.png';
import AvatarJoji from '../../Assets/Images/joji.png';
import AvatarRiri from '../../Assets/Images/riri.png';
import { fetch } from '../../Generated/utils/dataAccess';

const ReportPage = () => {
  const { code } = useParams();
  const [data, setData] = useState(false);
  const History = useHistory();

  useEffect(() => {
    if (!data) {
      fetch(`reports/simple/${code}`).then((response) => {
        return response.json();
      }).then((result) => {
        setData(result.data);
      }).catch(() => {
        History.push({
          pathname: '/account',
        });
      });
    }
  }, [data, code, History]);

  const page1 = createRef(null);
  const page2 = createRef(null);
  const multiplePage = createRef(null);

  const getImage = (username) => {
    switch (username) {
      case 'xav':
        return AvatarXav;
      case 'sitta':
        return AvatarSitta;
      case 'chacha':
        return AvatarChaca;
      case 'riri':
        return AvatarRiri;
      default:
        return AvatarJoji;
    }
  };

  return (
    <>
    {data && (
    <div className="multiplePage" ref={multiplePage}>
    <div className="first-page-container page" ref={page1}>
        <img src={page1Background} alt="first-page" className="first-page-background" />
        <div className="first-page">
            <p className="congrast">CONGRATULATIONS</p>
            <p className="we-proudly">we proudly say to</p>
            <p className="name">{ data.name }</p>
            <table>
                <thead>
                    <tr>
                        <th className="name-header">Latest Topics <br/> Played</th>
                        <th className="correct-header">Correct Answer</th>
                        <th className="incorrect-header">Incorrect Answer</th>
                        <th className="time-header">Total Time <br/> Taken</th>
                    </tr>
                </thead>
                {data.topic_completed.length && data.topic_completed.map((item, index) => (
                <tbody key={index}>
                    <tr>
                        <td className="content">{ item.topic_name }</td>
                        <td className="content">{ item.total_correct }</td>
                        <td className="content">{ item.total_wrong }</td>
                        <td className="content">{ item.total_time }</td>
                    </tr>
                </tbody>
                ))
                }
            </table>
            <p className="fun-facts">Fun Facts</p>
            <p className="ff-desc">{ data.fun_fact.field_1 }</p>
        </div>
    </div>
    <div className="second-page-container page" ref={page2}>
        {data.position === 'first' && (
            <img src={page2BackgroundFirst} alt="second-page" className="second-page-background" />
        )}
        {data.position === 'middle' && (
            <img src={page2BackgroundMiddle} alt="second-page" className="second-page-background" />
        )}
        {data.position === 'last' && (
            <img src={page2BackgroundLast} alt="second-page" className="second-page-background" />
        )}
        <div className={`second-page ${data.position}`}>
            <p className="leaderboard">Leaderboard</p>
            <div className={`rank ${data.position}-1 ${data.position === 'first' ? 'own' : 'other'}`}>
                <div className="rank-sub num">
                    {data.person_1.rank}.
                </div>
                <div className="rank-sub avatar">
                    <img src={getImage(data.person_1.avatar_name)} alt="person_1" />
                </div>
                <div className="rank-sub name">
                        {data.person_1.name}
                </div>
                <div className="rank-sub mp">
                        {data.person_1.mindpower}
                </div>
            </div>
            <div className={`rank ${data.position}-2 ${data.position === 'middle' ? 'own' : 'other'}`}>
                <div className="rank-sub num">
                    {data.person_2.rank}.
                </div>
                <div className="rank-sub avatar">
                    <img src={getImage(data.person_2.avatar_name)} alt="person_2" />
                </div>
                <div className="rank-sub name">
                        {data.person_2.name}
                </div>
                <div className="rank-sub mp">
                        {data.person_2.mindpower}
                </div>
            </div>
            <div className={`rank ${data.position}-3 ${data.position === 'last' ? 'own' : 'other'}`}>
                <div className="rank-sub num">
                    {data.person_3.rank}.
                </div>
                <div className="rank-sub avatar">
                    <img src={getImage(data.person_3.avatar_name)} alt="person_3" />
                </div>
                <div className="rank-sub name">
                        {data.person_3.name}
                </div>
                <div className="rank-sub mp">
                        {data.person_3.mindpower}
                </div>
            </div>

            <p className="have">Now We Have,</p>
            <div className="stats">
                <div className="box lesson">
                    <span className="count">{data.lesson_count}</span><br/>
                    Number of Lessons Available
                </div>
                <div className="box liveclassName">
                    <span className="count">{data.liveclass_count}</span><br/>
                    Number of Live className Available
                </div>
                <div className="box week">
                    <span className="count">{data.topic_week}</span><br/>
                    Number of Lessons Created per Week
                </div>
            </div>

            <div className="plan">
                <div className="plan-box plan-desc">
                    Make sure you subscribe so that your child can continue study!
                </div>
                {/* <div className="plan-box plan-price">
                    <span className="pintar-plan">PINTAR PLAN</span><br/>
                    <span className="plan-name">(MONTHLY)</span><br/>
                    <span className="prev-price">
                        <span className="strikeout">
                        IDR 79.000
                        </span>
                    </span><br/>
                    <span className="price">IDR 49.000</span>
                </div> */}
            </div>

            <div className="sosial">
                <div className="sosial-box web">
                    <span>titikpintar.id</span>
                </div>
                <div className="sosial-box fb">
                    <span>Titik Pintar</span>
                </div>
                <div className="sosial-box yt">
                    <span>Titik Pintar</span>
                </div>
                <div className="sosial-box ig">
                    <span>titikpintar</span>
                </div>
            </div>
        </div>
    </div>
    </div>
    )}
    <br/>
    <br/>
    {data && data.name && data.shorted_url && (
        <Downloader
            filename={`Laporan Perkembangan ${data.name}`}
            image={multiplePage}
            pdf={document.getElementsByClassName('page')}
            whatsapp={`https://api.whatsapp.com/send?text=${encodeURIComponent(`${data.shorted_url} Lihat perkembangan ${data.name}`)}`}
            format='a4'
        />
    )}
    </>
  );
};

export default ReportPage;
