export const englishMessages = {
  // Login Page
  jumboText: 'Hello!',
  emailFormPlaceholder: 'E-Mail / Phone Number',
  passwordFormPlaceholder: 'Enter your password',
  forgotButton: 'Forgot Password?',
  loginButton: 'Log in',
  signupButton: 'Sign up',

  // Sign Up
  signUp: 'Sign Up Now',
  parent: 'Parent',
  child: 'Child',
  phoneNumber: 'Phone',
  passwordPlaceholder: 'Enter your password',
  successSignUp: 'You are successfully registered',
  freePlan: 'Free Plan',
  limitedLesson: 'Maximum 2 lessons / day',
  limitedPlay: 'Free 30 days pintar plan',
  limitedSaved: 'Score and lesson progress will be saved',

  // Login Validation
  usernameRequired: 'Please enter your email or phone number!',
  passwordMin: 'Your password is too short!',
  passwordRequired: 'Please enter your password!',
  errorLogin: 'The Email / Password combination you have entered is incorrect',

  // Account Page
  logout: 'Log Out',
  account: 'Account',
  addChildButton: 'Add Child',
  seeBillingHistory: 'See Billing History',
  nextBillingDate: 'Next Billing Date',
  noPhoneNumber: 'No Phone Number provided',
  noEmail: 'No Email provided',
  childAndPlan: 'Child & Plan',
  upgradePlan: 'Upgrade Pintar Plan',
  extendsPlan: 'Extends Pintar Plan',
  seePlan: 'View Subscription Status',

  // Add Child Page
  addChild: 'Add Child',
  nameLabel: 'Nickname',
  nameChildPlaceholder: "Enter your child's name",
  usernamePlaceholder: "Enter your child's username",
  gender: 'Gender',
  genderChildPlaceholder: "Enter your child's gender",
  schoolPlaceholder: 'School',
  schoolChildPlaceholder: "Enter your child's school",
  gradeChildPlaceholder: "Enter your child's grade",
  createChildAccount: 'Create Account',
  boy: 'Boy',
  girl: 'Girl',
  unFinishedForm: 'Please complete your form before submitting!',
  successAddChild: 'Children account(s) created successfully!',

  // Edit Child Page
  editChildHeader: 'Edit Child Profile',
  saveChanges: 'Save Changes',
  freePackage: 'Free Package',
  standard: 'Standard',
  upgrade: 'Upgrade',
  successEditChild: 'Account(s) Updated Successfully!',

  // Edit Parent Page
  editParentHeader: 'Edit Email and Phone Number',
  parentFullName: 'Full Name',
  parentFullNamePlaceholder: 'Enter Parent Name',
  parentEmail: 'Email Address',
  parentEmailPlaceholder: 'Enter Parent Email Address',
  parentPhone: 'Phone Number',
  parentPhonePlaceholder: 'Enter Parent Phone Number',
  parentPassword: 'Password',

  // Upgrade Page
  upgradeHeader: 'Upgrade Account',
  voucherCode: 'Voucher Code',
  voucherCodePlaceholder: 'Enter your voucher code here',
  checkVoucher: 'Check Voucher',
  paymentMethod: 'Payment Method',
  continue: 'Continue',
  fullAccess: 'Full Access Level',
  unlimited: 'Unlimited avatars to play',
  saved: 'Saved score & game history',
  discount: 'Discount',
  totalPayment: 'Total Payment',
  subscriptionType: 'Subscription Types',

  // Telkomsel Payment Page
  paymentHeader: 'Pay via Telkomsel',
  paymentPhoneNumber: 'Phone Number',
  paymentPhoneNumberFalse: "The number you've entered is incorrect. Please try again",
  paymentField: 'Enter your phone number',
  paymentVerification: 'Verification',
  paymentOTP: 'OTP Code',
  paymentOTPFalse: "The code you've entered is incorrect or already expired",
  paymentOTPField: 'Please enter the code',
  resendOTP: "Didn't receive the code?",
  resend: 'Resend again',

  // Billing Page
  billingHeader: 'Billing History',
  noBilling: 'There are no payments made by you',
  paymentFor: 'Payment for',

  // Forget Password Page
  passwordReset: 'Password Reset',
  backLogin: 'Back To Login',
  submit: 'Submit',
  emailRequired: 'Please enter your email!',
  emailNotCorrect: 'Please input your email correctly!',
  checkEmail: 'Success! Please check your email.',
  emailNotFound: "Sorry, We couldn't find your account.",

  // Transaction Success Page
  congrats: 'Congrats!',
  transactionSuccess: 'Your child can now continue his/her fun & play!',
  transactionDetail: 'Transaction Details',
  premiumFor: 'Premium for',
  time: 'Time',
  subscriptionAmount: 'Subscription Amount',
  referenceId: 'Reference ID',

  days: 'days',
  months: 'months',
  years: 'years',

  threedays: '3 Days',
  weekly: 'Weekly',
  monthly: 'Monthly',
  yearly: 'Yearly',

  pleaseWait: 'Please Wait',
  tokenExpired: 'Your link is invalid or expired.',

  viewSubscription: 'View Subscription Status',
  subscriptionStatus: 'Subscription Status',
  subscriptionTypes: 'Subscription Types',
  subscriptionExtends: 'Extends Subscription',
  subscriptionUnsubscribe: 'Unsubscribe',
  subscriptionModalHeader: 'Are you sure to unsubscribe from this Pintar Plan?',
  subscriptionModalBody: 'Your Children might lose their progress and get lesson limitation in the application.',
  subscriptionSure: "I'm sure",
  subscriptionUnsure: 'No, Wait',

  telkomselNeedVoucher: 'Telkomsel Payment need Voucher!',
  firstPage: 'Please enter your phone number and get the OTP Verification Code in your WhatsApp or sms.',
  helpSignUp: 'Don’t have an account yet?',
  helpSignIn: 'Already have an account?',
  signUpHere: 'Sign Up',
  signInHere: 'Sign In',
  clickButton: 'Click the button to get the code',
  second: 'seconds',

  username: 'Username',
  usernameField: 'Please enter your username',

  imsure: 'Im sure',
  nowait: 'No, wait',
  textModal: 'You will be redirect to Input Number Page. Are you sure to go back?',
  pModal: 'Your progress own;t be saved.',

  teacherPage: 'Teacher Page',
  teacherWelcome: 'Hi, Welcome Back!',
  liveClassList: 'Live Class List',
  liveClassPreview: 'Preview Live Class',

  PULSA: 'Pulsa',
  EWALLET: 'E-Wallet',
  CREDIT_CARD: 'Kartu Kredit',
  BANK_TRANSFER: 'Transfer Bank',
  OUTLET: 'Outlet',
  QRCODE: 'QR-Code',
};

export const bahasaMessages = {
  // Login Page
  jumboText: 'Halo!',
  emailFormPlaceholder: 'Alamat Email / Nomor Telpon',
  passwordFormPlaceholder: 'Masukkan Kata Sandi Kamu',
  forgotButton: 'Lupa Kata Sandi?',
  loginButton: 'Masuk',
  signupButton: 'Daftar',

  // Sign Up
  signUp: 'Daftar Sekarang',
  parent: 'Orang Tua',
  child: 'Anak',
  phoneNumber: 'Nomor Telpon',
  passwordPlaceholder: 'Masukan Kata Sandi',
  successSignUp: 'Kamu berhasil daftar',
  freePlan: 'Free Plan',
  limitedLesson: 'Maksimal 2 pelajaran / hari',
  limitedPlay: 'Gratis 30 hari pintar plan',
  limitedSaved: 'Skor dan kemajuan pelajaran akan disimpan',

  // Login Validation
  usernameRequired: 'Alamat email atau nomor telpon wajib diisi!',
  passwordMin: 'Kata sandi kamu terlalu pendek!',
  passwordRequired: 'Kata sandi wajib diisi',
  errorLogin: 'Kombinasi alamat email / kata sandi yang kamu masukkan salah',

  // Account Page
  logout: 'Keluar',
  account: 'Akun',
  addChildButton: 'Tambah Anak',
  seeBillingHistory: 'Cek Sejarah Pembayaran',
  nextBillingDate: 'Tagihan Selanjutnya',
  noPhoneNumber: 'Nomor Telpon Tidak Tersedia',
  noEmail: 'Alamat Email Tidak Tersedia',
  childAndPlan: 'Akun & Paket Langganan',
  upgradePlan: 'Mulai Berlangganan Paket Pintar',
  extendsPlan: 'Lanjutkan Berlangganan Paket Pintar',
  seePlan: 'Lihat Status Langganan',

  // Add Child Page
  addChild: 'Tambah Anak',
  nameLabel: 'Nama',
  nameChildPlaceholder: 'Masukkan nama anakmu',
  usernamePlaceholder: 'Masukan username anakmu',
  gender: 'Jenis Kelamin',
  genderChildPlaceholder: 'Masukkan jenis kelamin anakmu',
  schoolPlaceholder: 'Sekolah',
  schoolChildPlaceholder: 'Masukkan sekolah anakmu',
  gradeChildPlaceholder: 'Masukkan kelas anakmu',
  createChildAccount: 'Buat Akun',
  boy: 'Laki - laki',
  girl: 'Perempuan',
  unFinishedForm: 'Mohon lengkapi formulir kamu sebelum mengirimkan!',
  successAddChild: 'Berhasil Membuat Akun Anak!',

  // Edit Child Page
  editChildHeader: 'Edit Profil Anak',
  saveChanges: 'Simpan Perubahan',
  freePackage: 'Paket Gratis',
  standard: 'Standar',
  upgrade: 'Paket Langganan',
  successEditChild: 'Berhasil Mengubah Akun!',

  // Edit Parent Page
  editParentHeader: 'Edit Email dan Nomor Telpon',
  parentFullName: 'Nama Lengkap',
  parentFullNamePlaceholder: 'Masukkan Nama Orang Tua',
  parentEmail: 'Alamat Email',
  parentEmailPlaceholder: 'Masukkan Alamat Email Orang Tua',
  parentPhone: 'Nomor Telpon',
  parentPhonePlaceholder: 'Masukkan Nomor Telpon Orang Tua',
  parentPassword: 'Kata Sandi',

  // Upgrade Page
  upgradeHeader: 'Tingkatkan Akun Anak',
  voucherCode: 'Kode Kupon',
  voucherCodePlaceholder: 'Masukkan Kode Kuponmu Disini',
  checkVoucher: 'Cek Kupon',
  paymentMethod: 'Metode Pembayaran',
  continue: 'Lanjutkan',
  fullAccess: 'Akses penuh untuk semua level',
  unlimited: 'Akses penuh untuk semua avatar ',
  saved: 'Dapat menyimpan skor & sejarah permainan',
  discount: 'Diskon',
  totalPayment: 'Total Pembayaran',
  subscriptionType: 'Jenis Langganan',

  // Telkomsel Payment Page
  paymentHeader: 'Pembayaran Telkomsel',
  paymentPhoneNumber: 'Nomor Telpon',
  paymentPhoneNumberFalse: 'Nomor Telpon yang Anda masukan salah. Silahkan coba lagi',
  paymentField: 'Masukan Nomor Telpon Anda',
  paymentVerification: 'Verifikasi',
  paymentOTP: 'Kode OTP',
  paymentOTPFalse: 'Kode OTP yang Anda masukan salah atau telah kedaluarsa. Silahkan coba lagi',
  paymentOTPField: 'Masukan Kode Anda',

  // Billing Page
  billingHeader: 'Sejarah Pembayaran',
  noBilling: 'Tidak ada pembayaran yang dilakukan olehmu',
  paymentFor: 'Pembayaran untuk',

  // Forget Password Page
  passwordReset: 'Reset Kata Sandi',
  backLogin: 'Kembali ke halaman Masuk',
  submit: 'Kirim',
  emailRequired: 'Alamat Email Wajib Diisi!',
  emailNotCorrect: 'Masukkan alamat email kamu secara benar!',
  checkEmail: 'Berhasil! Mohon cek alamat email kamu.',
  emailNotFound: 'Maaf, akunmu tidak ditemukan.',

  // Transaction Success Page
  congrats: 'Selamat!',
  transactionSuccess: 'Kini anak anda dapat melanjutkan petualangannya yang menakjubkan bersama Titik Pintar!',
  transactionDetail: 'Detail Transaksi',
  premiumFor: 'Premium untuk',
  time: 'Waktu Pembayaran',
  subscriptionAmount: 'Biaya Langganan',
  referenceId: 'Referensi ID',
  resendOTP: 'Tidak menerima kode?',
  resend: 'Kirim Kembali',

  days: 'hari',
  months: 'bulan',
  years: 'tahun',

  threedays: 'Per 3 Hari',
  weekly: 'Per Minggu',
  monthly: 'Per Bulan',
  yearly: 'Per Tahun',

  pleaseWait: 'Mohon Tunggu',
  tokenExpired: 'Tautan kamu tidak valid atau kadaluwarsa.',

  viewSubscription: 'Lihat Status Langganan',
  subscriptionStatus: 'Status Langganan',
  subscriptionTypes: 'Tipe Langganan',
  subscriptionExtends: 'Perpanjang Langganan',
  subscriptionUnsubscribe: 'Berhenti Berlangganan',
  subscriptionModalHeader: 'Anda yakin ingin berhenti berlangganan Paket Pintar ini?',
  subscriptionModalBody: 'Anak Anda mungkin kehilangan kemajuan dan mendapat batasan pelajaran di dalam aplikasi.',
  subscriptionSure: 'Saya yakin',
  subscriptionUnsure: 'Tidak, Tunggu',

  telkomselNeedVoucher: 'Langganan Telkomsel membutuhkan voucher!',
  firstPage: 'Mohon masukan nomor telephone dan dapatkan kode verifikasi OTP pada WhatsApp atau SMS Anda',
  helpSignUp: 'Belum punya akun?',
  helpSignIn: 'Sudah punya akun?',
  signUpHere: 'Daftar',
  signInHere: 'Masuk',
  clickButton: 'Klik tombol untuk dapatkan kode OTP',
  second: 'detik',

  username: 'Username',
  usernameField: 'Silahkan masukan username Anda',

  imsure: 'Saya yakin',
  nowait: 'Tidak, tunggu',
  textModal: 'Anda akan kembali kepada input angka. Apakah Anda yakin?',
  pModal: 'Progress Anda akan hilang',

  teacherPage: 'Halaman Guru',
  teacherWelcome: 'Halo, Selamat Datang!',
  liveClassList: 'Daftar Kelas Langsung',
  liveClassPreview: 'Lihat Kelas Langsung',

  PULSA: 'Pulsa',
  EWALLET: 'E-Wallet',
  CREDIT_CARD: 'Kartu Kredit',
  BANK_TRANSFER: 'Transfer Bank',
  OUTLET: 'Outlet',
  QRCODE: 'QR-Code',
};
