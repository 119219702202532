import { fetch } from '../../utils/dataAccess';

export function sendEvent(values) {
    return dispatch => {
        return fetch('activities/record_activity', { method: 'post', body: JSON.stringify(values) })
            .then(response => {
                console.log(response.json());
                return response.json();
            })
            .then(retrieved => {
            })
            .catch(e => {
            });
    };
}