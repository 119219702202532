export function changeLang(lang) {
  return { type: 'CHANGE_LANGUAGE', lang };
}

export function changeLanguage(lang = 'id') {
  return dispatch => {
    dispatch(changeLang(lang));
  };
}

