import CryptoJS from 'crypto-js';
import { HASH_DECRYPT } from '../../Constants/Constants';
import { fetch } from '../../Generated/utils/dataAccess';

export const encryptData = (data) => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), HASH_DECRYPT).toString();
};

export const decryptData = (ciphertext) => {
  if (ciphertext) {
    const bytes = CryptoJS.AES.decrypt(ciphertext, HASH_DECRYPT);
    try {
      return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    } catch (err) {
      return null;
    }
  }
  return null;
};

export const validateEmail = (email) => {
  // eslint-disable-next-line max-len
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const getSystemSettings = (settings, setState) => {
  fetch(`system_settings/${settings}`, { method: 'get' })
    .then((response) => {
      return response.json();
    })
    .then((receive) => {
      if (receive && receive.data && receive.data.value) {
        return setState(receive.data.value);
      }
      return false;
    })
    .catch(() => { return false; });
};

export const decodeBase64 = (str) => {
  return decodeURIComponent(atob(str).split('').map((c) => {
    return `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`;
  }).join(''));
};
