/* eslint-disable */
import axios from 'axios';
import { toast } from 'react-toastify';

export const isSuccess = (value) => ({
  type: 'FORGOT_IS_SUCCESS',
  payload: value
});

export const isError = (value) => ({
  type: 'FORGOT_IS_ERROR',
  payload: value
});

export const isLoading = (bool) => ({
  type: 'IS_LOADING',
  payload: bool,
})

export const requestForgotPassword = (payload, History, langData) => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    try {
      const { data } = await axios.post('/request_reset_password', payload);
      if (data) {
        dispatch(isLoading(false));
        dispatch(isSuccess(true));
        toast.success(langData.checkEmail);
      } else {
        return new Error();
      }
    } catch (error) {
      dispatch(isLoading(false));
      toast.error(langData.emailNotFound);
      return dispatch(isError(true));
    }
  };
};

/* eslint-enable */
