import { fetch } from '../../utils/dataAccess';

export function error(error) {
  return { type: 'GRADE_LIST_ERROR', error };
}

export function loading(loading) {
  return { type: 'GRADE_LIST_LOADING', loading };
}

export function success(retrieved) {
  return { type: 'GRADE_LIST_SUCCESS', retrieved };
}

export function list(page = 'grades') {
  return dispatch => {
    dispatch(loading(true));
    dispatch(error(''));

    fetch(page)
      .then(response =>
        response
          .json()
          .then(retrieved => {
            dispatch(loading(false));
            dispatch(success(retrieved.data));    
          })
      )
      .catch(e => {
        dispatch(loading(false));
        dispatch(error(e.message));
      });
  };
}

