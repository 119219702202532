import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import firebase from 'firebase/app';
import 'firebase/analytics';
import Navbar from '../../Components/Navbar/Navbar';
import PlanCard from '../../Components/PlanCard/PlanCard';
import Button from '../../Components/Button/Button';
import EditButton from '../../Components/EditButton/EditButton';
import { APP_NAME } from '../../Constants/Constants';

import { retrieve as accountActionRetrieve } from '../../Generated/actions/account/show';
import { addToEdit as childrenActionAddToEdit } from '../../Generated/actions/children/update';
import { addToEdit as parentActionAddToEdit } from '../../Generated/actions/parent/update';
import { sendEvent } from '../../Generated/actions/event/create';
import { updateTags } from '../../Generated/utils/OneSignal';
import './AccountPage.scss';

// import { getSystemSettings } from '../../Utils/Helper/helpers';

const AccountPage = () => {
  // States

  const analytics = firebase.analytics();

  const History = useHistory();

  const dispatch = useDispatch();

  const accountDetails = useSelector((state) => state.account.show);

  const plansSelector = useSelector((state) => state.plans.show);

  const language = useSelector((state) => state.language.change);
  const langData = language.lang ? language.lang.data : {};
  const [paymentActive, setPaymentActive] = useState('false');

  // Effects
  useEffect(() => {
    document.title = `${langData.account} - ${APP_NAME}`;
  });

  useEffect(() => {
    // getSystemSettings('is_monetization_active', setPaymentActive);
  }, [setPaymentActive]);

  useEffect(() => {
    dispatch(
      sendEvent({
        activity_type: 'OPEN_SCREEN',
        title: 'open_screen',
        screen_name: 'AccountAndSubscription',
        is_admin: 'No',
        payload: {
          source: 'account_login',
        }
      })
    );
  }, [dispatch]);

  useEffect(() => {
    dispatch(accountActionRetrieve());
  }, [dispatch]);

  // Functions

  const onEditParentClick = (account) => {
    dispatch(parentActionAddToEdit(account));
    History.push(`/edit-parent/${account.id}`);
  };

  const onEditClick = (account) => {
    dispatch(childrenActionAddToEdit(account));
    History.push(`/edit-child/${account.id}`);
  };

  const onUpgradeClick = (account) => {
    analytics.logEvent('child_selection', { user_id: account.id });
    updateTags(account.id, { payment_status: 'child_selection' });

    dispatch(childrenActionAddToEdit(account));
    if (account.subscription.is_free) {
      History.push(`/upgrade/${account.id}`);
    } else {
      History.push({
        pathname: `/subscription-status/${account.id}`,
        state: { account }
      });
    }
  };

  // Render Functions
  const renderHeader = () => {
    return (
      <div className='account-page-header' >
        <span className='account-page-header-heading' >{langData.account}</span>

        <div className='account-page-header-details' >
          <span className='account-page-header-details-email' >
            {accountDetails.retrieved ? (
              <div>
              {accountDetails.retrieved.phone_number}<br/>
              {accountDetails.retrieved.email}
              </div>
            ) : ''}
          </span>
          <br/>
          <span>Ubah Akun </span>
          <EditButton onClick={() => onEditParentClick(accountDetails.retrieved)} />
        </div>
      </div>
    );
  };

  const renderChildPlan = () => {
    const childrenAccounts = accountDetails.retrieved ? accountDetails.retrieved.children_accounts || [] : [];
    return (
      <div className='account-page-child-plan' >
        <span className='account-page-child-plan-heading' >{langData.childAndPlan}</span>

        {childrenAccounts.map((account) =>
          <PlanCard
            account={account}
            key={account.id}
            onEditClick={onEditClick}
            upgradePlan={plansSelector.retrieved ? plansSelector.retrieved : {}}
            onUpgradeClick={onUpgradeClick}
            onlyShow={paymentActive !== 'true'}
          />)}
      </div>
    );
  };

  const renderButtons = () => {
    return (
      <>
        <div className='account-page-btn add' >
          <Button block onClick={() => History.push('/add-child')} >
            <i className='fas fa-plus' /> {langData.addChildButton}
          </Button>
        </div>
        {paymentActive === 'true' ? (
          <div className='account-page-btn history' >
            <Button block type='secondary' onClick={() => History.push('/billing')} >
              {langData.seeBillingHistory}
            </Button>
          </div>
        ) : ''}
      </>
    );
  };

  return (
    <div className='page-container account-page' >
      <Navbar />
      <div className='body-content' >
        {renderHeader()}
        <div className='account-page-border' />
        {renderChildPlan()}
        {renderButtons()}
      </div>
    </div>
  );
};

export default AccountPage;
