import React from 'react';
import { Route } from 'react-router-dom';

export const OpenRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} component={(props) => {
    return (
      <Component {...props} />
    );
  }
  } />
);

export default OpenRoute;
