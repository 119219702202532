import React, {
  useEffect, useRef, useState, useCallback
} from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import 'firebase/analytics';
import uuid from 'react-uuid';
import useWebSocket from 'react-use-websocket';
import Emoji from 'react-emoji-render';
import InputEmoji from 'react-input-emoji';
import { sha256 } from 'js-sha256';
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/id';
import 'moment-timezone';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import {
  ROCKET_CHAT,
  WEBSOCKET_ROCKET_CHAT,
  IMAGE_BASE_URL,
  TEACHER_USERNAME,
  TEACHER_PASSWORD,
  WORKING_DAY_END,
  WORKING_HOURS_END,
  WORKING_HOURS_START,
  WORKING_HOURS_TZ
} from '../../Constants/Constants';
import './DoubtClearingChatPage.scss';
import { retrieve as accountActionRetrieve } from '../../Generated/actions/account/show';
import { initiate, teacherResponse } from '../../Generated/actions/account/event';
import { createLiveChatAccount } from '../../Actions/Auth';

import Send from '../../Assets/Images/send_orange.png';
import Upload from '../../Assets/Images/upload.png';
import Logo from '../../Assets/Images/Logo.png';

moment.locale('id');
window.scrollTo(0, 0);

const DoubtClearingChatPage = () => {
  // States
  const dispatch = useDispatch();
  const location = useSelector((state) => state.router.location);
  const [userId, setUserId] = useState();
  const [authToken, setAuthToken] = useState();
  const [createDM, setCreateDM] = useState(1);
  const [roomID, setRoomID] = useState(0);
  const [message, setMessage] = useState([]);
  const bottomChat = useRef(null);
  const [text, setText] = useState('');
  const [session, setSession] = useState();
  const [pollLoading, setPollLoading] = useState(false);
  const [teacherPresence, setTeacherPresence] = useState(false);
  const accountDetails = useSelector((state) => state.account.show);
  const inputFile = useRef(null);
  const [alreadyVote, setAlreadyVote] = useState(false);
  const History = useHistory();
  const [childMessage, setChildMessage] = useState(true);
  const [teacherMessage, setTeacherMessage] = useState(true);
  const [autoResponse, setAutoResponse] = useState(true);

  const {
    sendMessage,
    lastMessage,
  } = useWebSocket(WEBSOCKET_ROCKET_CHAT);

  const language = useSelector((state) => state.language.change);
  const langData = language.lang ? language.lang.data : {};

  const scrollToBottomChat = () => {
    if (bottomChat.current !== null) {
      bottomChat.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const logout = () => {
    localStorage.removeItem('teacher');
    localStorage.removeItem('token');
    localStorage.removeItem('child');
    History.push('/');
  };

  if (!location.state) {
    logout();
  }

  const connect = useCallback(() =>
    sendMessage(
      JSON.stringify({ msg: 'connect', version: '1', support: ['1'] })
    ), [sendMessage]);

  useEffect(() => {
    const now = moment();
    const format = 'hh:mm';
    const beforeTime = moment.tz(WORKING_HOURS_START, format, WORKING_HOURS_TZ);
    const afterTime = moment.tz(WORKING_HOURS_END, format, WORKING_HOURS_TZ);
    if (now.isoWeekday() > WORKING_DAY_END) {
      setAutoResponse(true);
    }
    if (now.isBetween(beforeTime, afterTime)) {
      setAutoResponse(false);
    }
  }, [setAutoResponse]);

  useEffect(() => {
    dispatch(accountActionRetrieve());
  }, [dispatch]);

  useEffect(() => {
    if (accountDetails.retrieved) {
      dispatch(createLiveChatAccount({
        name: `${accountDetails.retrieved.first_name} ${accountDetails.retrieved.last_name}`,
        email: `${accountDetails.retrieved.username}@titikpintar.id`,
        pass: 'rocketchatpintar',
        username: accountDetails.retrieved.username
      }, History));
    }
  }, [dispatch, accountDetails.retrieved, History]);

  const teacherAutoReply = () => {
    sendMessage(JSON.stringify({ msg: 'connect', version: '1', support: ['1'] }));
    sendMessage(JSON.stringify({
      msg: 'method',
      method: 'login',
      id: 'login-to-rocket-chat',
      params: [
        {
          user: { username: TEACHER_USERNAME },
          password: {
            digest: sha256(TEACHER_PASSWORD),
            algorithm: 'sha-256'
          }
        }
      ]
    }));
    const msg = `Halo, ${accountDetails.retrieved.first_name}, Alby di sini.
    Kami akan kembali ke kantor untuk membantu Anda selama jam kerja kami: Senin - Jumat, 10.00 - 18.00 WIB.`;
    const msg2 = 'Tinggalkan pesan kamu dan kami akan membantumu nanti.';
    sendMessage(JSON.stringify({
      msg: 'method',
      method: 'sendMessage',
      id: 'send-message',
      params: [
        {
          _id: uuid(),
          rid: roomID,
          msg
        }
      ]
    }));
    sendMessage(JSON.stringify({
      msg: 'method',
      method: 'sendMessage',
      id: 'send-message',
      params: [
        {
          _id: uuid(),
          rid: roomID,
          msg: msg2
        }
      ]
    }));
  };

  useEffect(() => {
    if (lastMessage && accountDetails.retrieved) {
      const data = JSON.parse(lastMessage.data);
      if (data.msg === 'ping') {
        sendMessage(JSON.stringify({ msg: 'pong' }));
      }
      if (data.msg === 'connected' || data.reason === 'Already connected') {
        setSession(data.session);
        sendMessage(JSON.stringify({
          msg: 'method',
          method: 'login',
          id: 'login-to-rocket-chat',
          params: [
            {
              user: { username: accountDetails.retrieved.username },
              password: {
                digest: sha256('rocketchatpintar'),
                algorithm: 'sha-256'
              }
            }
          ]
        }));
        axios({
          method: 'POST',
          url: `${ROCKET_CHAT}/api/v1/login`,
          data: ({
            username: accountDetails.retrieved.username,
            password: 'rocketchatpintar'
          })
        }).then((response) => {
          setAuthToken(response.data.data.authToken);
          setUserId(response.data.data.userId);
          if (accountDetails.retrieved.selected_avatar.image_url !== response.data.data.me.avatarUrl) {
            axios({
              method: 'POST',
              url: `${ROCKET_CHAT}/api/v1/users.setAvatar`,
              headers: {
                'X-Auth-Token': response.data.data.authToken,
                'X-User-Id': response.data.data.userId,
              },
              data: ({
                avatarUrl: `${IMAGE_BASE_URL}${accountDetails.retrieved.selected_avatar.image_url}`,
              })
            }).then(() => {
            }).catch(() => {
              // toast(error);
            });
          }
        }).catch(() => {
          // toast(error);
        });
      }
      if ((session && createDM) || data.collection === 'users') {
        sendMessage(JSON.stringify({
          msg: 'method',
          method: 'createDirectMessage',
          id: 'creating-room',
          params: [TEACHER_USERNAME]
        }));
        setCreateDM(0);
      }
      if (data.result && data.result.rid) {
        setRoomID(data.result.rid);
        sendMessage(JSON.stringify({
          msg: 'sub',
          id: 'stream-messages',
          name: 'stream-room-messages',
          params: [
            data.result.rid,
            false
          ]
        }));
        sendMessage(JSON.stringify({
          msg: 'method',
          method: 'rooms/get',
          id: 'get-rooms',
          params: [null]
        }));
        sendMessage(JSON.stringify({
          msg: 'method',
          method: 'loadHistory',
          id: 'load-history',
          params: [data.result.rid, null, 20, null]
        }));
      }
      if (data.msg === 'result' && data.result && data.result.messages) {
        setMessage(data.result.messages.reverse());
      }
      if (data.msg === 'changed' && data.collection === 'stream-room-messages') {
        if (data.fields && data.fields.args) {
          const found = message.findIndex((el) => el._id === data.fields.args[0]._id);
          if (found < 0) {
            const newChat = data.fields.args[0];

            // send teacher response event
            if (teacherMessage && newChat.u.username === TEACHER_USERNAME) {
              dispatch(teacherResponse({
                user_id: accountDetails.retrieved.id,
                teacher_username: TEACHER_USERNAME,
                token: location.state.token,
                message: newChat.msg
              }));
              setTeacherMessage(false);
            }

            setMessage([...message, newChat]);

            if (autoResponse) {
              teacherAutoReply();
              setAutoResponse(false);
            }
            scrollToBottomChat();
          } else {
            const newChat = data.fields.args[0];
            const oldMessage = message;
            oldMessage[found] = newChat;
            setMessage(oldMessage);
            setPollLoading(false);
          }
        }
      }
    } else {
      connect();
    }
    // eslint-disable-next-line
  }, [lastMessage, accountDetails.retrieved]);

  // Get users presences
  useEffect(() => {
    if (userId && authToken && !teacherPresence) {
      axios({
        method: 'GET',
        url: `${ROCKET_CHAT}/api/v1/users.getPresence?userId=${userId}`,
        headers: {
          Accept: '*/*',
          'Content-Type': 'application/json',
          'X-User-Id': userId,
          'X-Auth-Token': authToken,
        },
      }).then((response) => {
        setTeacherPresence(response.data);
      });
    }
  }, [authToken, userId, teacherPresence, message]);

  useEffect(() => {
    scrollToBottomChat();
  }, [message]);

  // Sending a messages
  const userSendMessage = () => {
    if (roomID) {
      sendMessage(JSON.stringify({
        msg: 'method',
        method: 'sendMessage',
        id: 'send-message',
        params: [
          {
            _id: uuid(),
            rid: roomID,
            msg: text
          }
        ]
      }));

      if (childMessage) {
        dispatch(initiate({
          user_id: accountDetails.retrieved.id,
          token: location.state.token,
          message: text
        }));
        setChildMessage(false);
      }

      setText('');
    }
  };

  const userVotes = async (mId, blockId, value) => {
    if (roomID && !pollLoading && !alreadyVote) {
      setPollLoading(true);
      fetch('https://rocketchat.titikpintar.id/api/apps/ui.interaction/c33fa1a6-68a7-491e-bf49-9d7b99671c48', {
        headers: {
          Accept: '*/*',
          'Content-Type': 'application/json',
          'X-User-Id': userId,
          'X-Auth-Token': authToken,
        },
        body: JSON.stringify({
          type: 'blockAction',
          actionId: 'vote',
          payload: {
            blockId,
            value
          },
          container: {
            type: 'message',
            id: mId
          },
          mid: mId,
          rid: roomID,
          triggerId: null
        }),
        method: 'POST',
      });
      setAlreadyVote(true);
    }
  };

  const handleUploadFile = (event) => {
    const data = new FormData();
    data.append('file', event.target.files[0]);
    axios({
      method: 'POST',
      url: `${ROCKET_CHAT}/api/v1/rooms.upload/${roomID}`,
      headers: {
        'Content-Type': 'multipart/form-data',
        'X-User-Id': userId,
        'X-Auth-Token': authToken,
      },
      data
    }).then(() => {
    }).catch(() => {
    });
  };

  const renderChat = (Chat, indexValue) => {
    let index = indexValue;
    if (!index) {
      index = 1;
    }

    return (
      <div key={Chat._id} >
        <div className={`chat ${Chat.u.username === accountDetails.retrieved.username ? 'reserve' : ''}`}>
          <img src={`${ROCKET_CHAT}/avatar/${Chat.u.username}`} alt={Chat.u.name} className="avatar" />
          <section className={`message ${Chat.u.username === TEACHER_USERNAME ? '' : 'right'}`}>
            <h6> {Chat.u.name} {Chat.u.username === TEACHER_USERNAME ? (<span>Guru</span>) : ''} </h6>
            {Chat.attachments && (
              <div>
                {Chat.attachments[0].description ? (<span>{Chat.attachments[0].description}<br /></span>) : ''}
                <LazyLoadImage
                  alt={`${ROCKET_CHAT}${Chat.attachments[0].image_url}`}
                  effect="blur"
                  src={`${ROCKET_CHAT}${Chat.attachments[0].image_url}`}
                  style={{ width: '200px', margin: '0px' }}
                />
              </div>
            )}
            <Emoji text={Chat.msg} />
            {Chat.blocks && Chat.blocks.length && Chat.blocks.map((block) => {
              if (block.type === 'section') {
                if (block.accessory && block.accessory.type === 'button') {
                  return (
                    <span
                      className="option"
                      onClick={() => userVotes(Chat._id, block.blockId, block.accessory.value)}
                    > {block.text.text} </span>
                  );
                }
              }
              if (block.type === 'context') {
                if (block.elements.length) {
                  return (
                    <span>{block.elements[0].text.replace('`', '').replace('`', '')}</span>
                  );
                }
              }
              return '';
            })}
          </section>
          <span className="moment">{moment(Chat.ts.$date).fromNow()}</span>
        </div>
      </div>
    );
  };

  const handleEnter = () => {
    userSendMessage();
  };

  const renderNavbar = () => {
    return (
      <div className='navbar-live-chat' >
        <img src={Logo} alt="" className='navbar-logo' />
        <span className='navbar-live-chat-logout' onClick={() => logout()} >{langData.logout}</span>
      </div>
    );
  };

  return (
    <div>
      <div className="header-live-chat-fixed">
        {renderNavbar()}
        <div className="wrapper-header-live-chat">
          <div className="header-live-chat-page">
            Doubt Clearing Chat
          </div>
        </div>
      </div>
      <div className='body-content-live-chat'>
        <div className='live-chat-page-content'>
          <div className="live-chat-page-live-chat-doubt">

            <div className="content-live-chat">
              <br />
              {message.length > 0 ? message.map((Chat, index) => {
                return renderChat(Chat, index);
              }) : (<div className="no-chat">Belum ada chat</div>)}

              <div ref={bottomChat}></div>
            </div>

          </div>
        </div>
      </div>
      <div className="footer-live-chat-wrapper">
        <div className="footer-live-chat">
          <InputEmoji
            value={text}
            onChange={setText}
            cleanOnEnter
            onEnter={handleEnter}
            placeholder="Tulis pesan ..."
          />
          <input
            type="file"
            className="form-control"
            name="upload_file"
            ref={inputFile}
            onChange={handleUploadFile}
            style={{ display: 'none' }}
          />
          <img src={Upload} alt="Upload" onClick={() => { inputFile.current.click(); }} />
          <img
            src={Send}
            alt="Upload"
            onClick={() => handleEnter()}
          />
        </div>
      </div>
    </div>
  );
};

export default DoubtClearingChatPage;
