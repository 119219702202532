/* eslint-disable consistent-return */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
// import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import EditButton from '../EditButton/EditButton';
import Label from '../Label/Label';
import Calendar from '../../Assets/Images/calendar.png';
import Button from '../Button/Button';
import './PlanCard.scss';

const PlanCard = ({
  account,
  onEditClick,
  onUpgradeClick,
  onlyShow = false,
}) => {
  // States

  const language = useSelector((state) => state.language.change);
  const langData = language.lang ? language.lang.data : {};

  // const History = useHistory();

  // Declarative Functions

  const getNextBillingDate = () => {
    let string = '';
    if (account.subscription) {
      string = moment(account.subscription.ends_at).add(1, 'days').format('DD MMMM YYYY');
    }
    return string;
  };

  const getAccountType = (subscription) => {
    if (subscription) {
      if (subscription.is_free) {
        return langData.standard;
      }

      return subscription.color;
    }
  };

  // Render Functions

  const renderBottom = () => {
    if (account.subscription && !account.subscription.is_free) {
      return (
        <div>
          <div className='plan-card-bottom-current'>
            <img src={Calendar} alt='' />
            <span className='plan-card-bottom-current-text'>
              {langData.nextBillingDate}: {getNextBillingDate()}
            </span>
          </div>
          <div className='plan-card-bottom-btn' >
            {onlyShow ? '' : (
              <Button block onClick={() => onUpgradeClick(account)}>
                {account.subscription.is_free ? langData.upgradePlan : langData.viewSubscription}
              </Button>
            )}
          </div>
        </div>
      );
    }
    return (
      <div className='plan-card-bottom-btn' >
        { onlyShow ? '' : (
          <Button block onClick={() => onUpgradeClick(account)}>
            {account.subscription.is_free ? langData.upgradePlan : langData.viewSubscription}
          </Button>
        )}
      </div>
    );
  };

  return (
    <div className='plan-card'>
      <span className='plan-card-heading'>
        {account.nick_name || 'No Nick Name'}
      </span>
      { onlyShow ? '' : (
        <EditButton onClick={() => onEditClick(account)} />
      )}

      {account.subscription && (
        <div className='plan-card-status'>
          <Label type={getAccountType(account.subscription)}>
            {account.subscription.is_free ? 'Standard' : account.subscription.name}
          </Label>
        </div>
      )}

      <div className='plan-card-labels'>
        <span className='plan-card-labels-text'>{account.school_name}</span>
        <span className='plan-card-labels-dot' />
        <span className='plan-card-labels-text'>
          {account.grade ? account.grade.name : ''}
        </span>
      </div>

      {renderBottom()}
    </div>
  );
};

PlanCard.propTypes = {
  account: PropTypes.object.isRequired,
  onEditClick: PropTypes.func,
  upgradePlan: PropTypes.object,
  onlyShow: PropTypes.bool,
};

export default PlanCard;
