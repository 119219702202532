import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { changeLanguage } from '../../Generated/actions/language/change';

import IndoFlag from '../../Assets/Images/indo-flag.png';
import UkFlag from '../../Assets/Images/uk flag.png';
import './Lang.scss';

const Lang = () => {
  const dispatch = useDispatch();
  const language = useSelector((state) => state.language.change);

  const langId = language.lang ? language.lang.lang : 'id';

  const changeLang = (lang) => {
    localStorage.setItem('language', lang);
    dispatch(changeLanguage(lang));
  };

  return (
    <div className='lang'>
        <img
            onClick={() => changeLang('en')}
            src={UkFlag}
            alt=""
            className={`lang-img ${langId === 'en' ? 'selected' : ''}`}
        />
        <img
            onClick={() => changeLang('id')}
            src={IndoFlag}
            alt=""
            className={`lang-img ${langId === 'id' ? 'selected' : ''}`}
        />
    </div>
  );
};

export default Lang;
