import React from 'react';
import Select from 'react-select';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import './DropdownFormComponent.scss';

const DropdownFormComponent = ({
  label,
  errorMessage,
  placeholder,
  options,
  isMulti,
  onChange,
  value,
  iconLabel,
  customLabel
}) => {
  const getValue = () => {
    if (value && (typeof value === 'string' ? value.length > 0 : true)) {
      return options.filter((option) => option.value === value);
    }

    return null;
  };

  const renderCustomLabel = (icon, iconName) => {
    return (
      <div className="form-input-custom-label">
        <div className="form-input-custom-label-wrapper-icon">
          <img src={icon} alt={iconName} />
        </div>
        {iconName}
      </div>
    );
  };

  return (
    <div className='form-input'>
    <Form.Group>
      <Form.Label className='form-input-label'>{label}</Form.Label>
        {iconLabel ? renderCustomLabel(iconLabel, customLabel) : ''}
        <Select
          classNamePrefix='dropdown-form-component'
          placeholder={placeholder}
          options={options}
          isMulti={isMulti}
          onChange={(option) => onChange(option.value)}
          value={getValue()}
        />
      <Form.Control.Feedback type="invalid">
        {errorMessage}
      </Form.Control.Feedback>
    </Form.Group>
  </div>

  );
};

DropdownFormComponent.propTypes = {
  label: PropTypes.string,
  errorMessage: PropTypes.any,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  isMulti: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.any,
  iconLabel: PropTypes.string,
  customLabel: PropTypes.string
};

export default DropdownFormComponent;
