import { toast } from 'react-toastify';
import { SubmissionError } from 'redux-form';
import { fetch } from '../../utils/dataAccess';
import { success as createSuccess } from './create';
import { loading, error } from './delete';


export function addToEdit(object) {
  return dispatch =>
    dispatch({ type: 'PARENT_ADD_TO_EDIT', data: object })
}

export function retrieveError(retrieveError) {
  return { type: 'PARENT_UPDATE_RETRIEVE_ERROR', retrieveError };
}

export function retrieveLoading(retrieveLoading) {
  return { type: 'PARENT_UPDATE_RETRIEVE_LOADING', retrieveLoading };
}

export function retrieveSuccess(retrieved) {
  return { type: 'PARENT_UPDATE_RETRIEVE_SUCCESS', retrieved };
}

export const retrieve = (id) => {
  return dispatch => {
    dispatch(retrieveLoading(true));

    return fetch(id)
      .then(response =>
        response
          .json()
          .then(retrieved => {
            dispatch(retrieveLoading(false));
            dispatch({ type: 'PARENT_ADD_TO_EDIT', data: retrieved.data })
          })
      )
      .catch(e => {
        dispatch(retrieveLoading(false));
        dispatch(retrieveError(e.message));
      });
  };
}



export function updateError(updateError) {
  return { type: 'PARENT_UPDATE_ERROR', updateError };
}

export function updateLoading(updateLoading) {
  return { type: 'PARENT_UPDATE_LOADING', updateLoading };
}

export function updateSuccess(updated) {
  return { type: 'PARENT_UPDATE_SUCCESS', updated };
}

export function update(id, values, History, langData) {
  return dispatch => {
    dispatch(updateError(null));
    dispatch(updateSuccess(null));
    dispatch(updateLoading(true));

    return fetch(id, {
      method: 'PUT',
      body: JSON.stringify(values)
    })
    .then(retrieved => {
      toast.success(langData.successEditChild);
      History.push('/account');
      dispatch({ type: 'PARENT_UPDATE_UPDATE_SUCCESS', retrieved })
      dispatch(updateLoading(false));
      // console.log('retrieved inside then of update PARENT', retrieved);
    })
      .catch(e => {
        dispatch(updateLoading(false));

        if (e instanceof SubmissionError) {
          dispatch(updateError(e.errors._error));
          throw e;
        }
        toast.error(e.message)

        dispatch(updateError(e.message));
      });
  };
}

export function reset() {
  console.log('reset action is called');
  return dispatch => {
    dispatch({ type: 'PARENT_UPDATE_RESET' });
    dispatch({ type: 'PARENT_ADD_TO_EDIT_RESET' });
    dispatch(error(null));
    dispatch(loading(false));
    dispatch(createSuccess(null));
  };
}

