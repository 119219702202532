/* eslint-disable no-use-before-define */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Navbar from '../../Components/Navbar/Navbar';
import Input from '../../Components/Input/Input';
import User from '../../Assets/Images/user.png';
import School from '../../Assets/Images/school.png';
import DropdownFormComponent from '../../Components/DropdownFormComponent/DropdownFormComponent';
import Button from '../../Components/Button/Button';
import Loading from '../../Components/Loading/Loading';
import { sendEvent } from '../../Generated/actions/event/create';
import { APP_NAME } from '../../Constants/Constants';

import { list as gradeActionList } from '../../Generated/actions/grade/list';
import { create as childrenActionCreate } from '../../Generated/actions/children/create';

const AddChild = () => {
  // States

  const History = useHistory();

  const dispatch = useDispatch();

  const gradesList = useSelector((state) => state.grade.list);
  // const createdChildren = useSelector((state) => state.children.create);

  const isLoading = useSelector((state) => state.children.create.loading);

  const language = useSelector((state) => state.language.change);
  const langData = language.lang ? language.lang.data : {};

  const genderOptions = [
    { label: langData.boy, value: 'boy' },
    { label: langData.girl, value: 'girl' },
  ];

  // Grades List for react-select component
  const [arrangedGradesList, setArrangedGradesList] = useState([]);

  const [childData, setChildData] = useState({
    fullname: '',
    username: '',
    school_name: '',
    grade_sorting_order: null,
    gender: '',
    item_id: 1,
  });

  // Effects
  useEffect(() => {
    document.title = `${langData.addChild} - ${APP_NAME}`;
  });

  useEffect(() => {
    dispatch(
      sendEvent({
        activity_type: 'OPEN_SCREEN',
        title: 'open_screen',
        screen_name: 'AddChild',
        is_admin: 'No',
        payload: {
          source: 'account_login',
        }
      })
    );
  }, [dispatch]);

  useEffect(() => {
    dispatch(gradeActionList());
  }, [dispatch]);

  useEffect(() => {
    let array = [];
    if (gradesList.retrieved) {
      array = arrangeGrades(gradesList.retrieved);
    }
    setArrangedGradesList(array);
  }, [gradesList]);

  // Functions

  const arrangeGrades = (array) => {
    const object = {};
    array.map((grade) => {
      object[grade.sorting_order] = {
        label: grade.name,
        value: grade.sorting_order,
        sorting_order: grade.sorting_order
      };
    });
    return Object.values(object);
  };

  const onChange = (name, value) => {
    const object = { ...childData, [name]: value };
    setChildData(object);
  };

  const validate = () => {
    if (!childData.grade_sorting_order) return false;
    if (!childData.fullname.length || !childData.gender.length) return false;
    return true;
  };

  const createChildAccount = (e) => {
    e.preventDefault();
    const isValidated = validate();
    if (!isValidated) return toast.warn(langData.unFinishedForm);
    dispatch(childrenActionCreate({ children: [childData] }, History, langData));
  };

  // Render Functions

  const renderSubmit = () => {
    return (
      <div className='edit-profile-submit' >
        <div className='edit-profile-submit-btn' >
          <Button block onClick={createChildAccount} >{langData.createChildAccount}</Button>
        </div>
        {/* <Button block type='secondary' onClick={() => History.push('/upgrade')} >Try Premium for Free</Button> */}
      </div>
    );
  };

  return (
    <div className='page-container' >
      {isLoading && <Loading />}
      <Navbar />
      <div className='body-content' >
        <div className='body-content-heading' >
          <i
            className='fal fa-arrow-left body-content-heading-back-arrow'
            onClick={() => History.goBack()}
          />
          {langData.addChild}
        </div>

        <span className='edit-profile-input-label' >
          <img src={User} className='edit-profile-input-label-img' alt='' />
          {langData.nameLabel}
        </span>

        <div className='edit-profile-input' >
          <Input
            placeholder={langData.nameChildPlaceholder}
            onChange={(e) => onChange('fullname', e.target.value)}
            value={childData.fullname}
          />
        </div>

        <div className='edit-profile-input' >
          <Input
            placeholder={langData.usernamePlaceholder}
            onChange={(e) => onChange('username', e.target.value)}
            value={childData.username}
          />
        </div>

        <div className='edit-profile-input' >
          <DropdownFormComponent
            placeholder={langData.genderChildPlaceholder}
            options={genderOptions}
            onChange={(value) => onChange('gender', value)}
            value={childData.gender}
          />
        </div>

        <span className='edit-profile-input-label' >
          <img src={School} alt="" className='edit-profile-input-label-img' />
          {langData.schoolPlaceholder}
        </span>

        <div className='edit-profile-input' >
          <Input
            placeholder={langData.schoolChildPlaceholder}
            onChange={(e) => onChange('school_name', e.target.value)}
            value={childData.school_name}
          />
        </div>

        <div className='edit-profile-input' >
          <DropdownFormComponent
            placeholder={langData.gradeChildPlaceholder}
            options={arrangedGradesList}
            onChange={(value) => onChange('grade_sorting_order', value)}
            value={childData.grade_sorting_order}
          />
        </div>

        {renderSubmit()}
      </div>
    </div>
  );
};

export default AddChild;
