import { fetch } from '../../utils/dataAccess';
import { toast } from 'react-toastify';
import { encryptData } from '../../../Utils/Helper/helpers';

// import { create as paymentsActionCreate } from '../../actions/payments/create';

export function error(error) {
  return { type: 'ACCOUNT_CREATE_ERROR', error };
}

export function loading(loading) {
  return { type: 'ACCOUNT_CREATE_LOADING', loading };
}

export function success(created) {
  return { type: 'ACCOUNT_CREATE_SUCCESS', created };
}

export function checkUsername(values, langData, History, analytics, setErrorMessage, setStep, setLoading) {
  return (dispatch) => {
    dispatch(loading(true));
    setLoading(true);

    const username = values.children.username;

    return fetch('check_username', { method: 'post', body: JSON.stringify({ username: username }) })
      .then((response) => {
        dispatch(loading(false));

        return response.json();
      })
      .then((retrieved) => {
        if (retrieved.success) {
          dispatch(loading(false));
          dispatch(create(values, langData, History, analytics, setStep, setLoading));
        } else {
          setLoading(false);
          setErrorMessage(retrieved.message);
          return new Error();
        }
      })
      .catch((e) => {
        const message = JSON.parse(e.message);
        setLoading(false);
        dispatch(loading(false));
        if (Array.isArray(message)) {
          setErrorMessage(message[0].message);
          dispatch(error(message));
        } else {
          setErrorMessage(e.message);
          dispatch(error(e.message));
        }
      });
  };
}

export function create(values, langData, History, analytics, setStep, setLoading) {
  return (dispatch) => {
    dispatch(loading(true));
    setLoading(true);

    // transform children object
    values = { ...values, children: [values.children] };

    let headers = new Headers();
    headers.set('Accept-Language', 'id');

    console.log(values);

    // if (values.plan.slug !== 'free' && values.payment_method.id !== undefined) {
    //   if (values.payment_method.payment_method === 'PULSA' && values.referral_code === "") {
    //     toast.error(langData.telkomselNeedVoucher);
    //     dispatch(loading(false));
    //     return;
    //   }
    // }

    return fetch('signup', {
      headers,
      method: 'post',
      body: JSON.stringify(values),
    })
      .then((response) => {
        dispatch(loading(false));

        return response.json();
      })
      .then((retrieved) => {
        console.log(retrieved);

        if (retrieved.access_token) {
          localStorage.setItem('token', retrieved.access_token);
          localStorage.setItem('payload', encryptData({role: 'parent'}));
        }

        dispatch(loading(false));
        toast.success(langData.successSignUp);
        History.push(`/`);

        analytics.logEvent('signup', {
          phone_number: values.phone_number,
        });

        dispatch(success(retrieved));
      })
      .catch((e) => {
        setStep('basic');
        const message = JSON.parse(e.message);

        dispatch(loading(false));
        setLoading(false);

        if (Array.isArray(message)) {
          toast.error(message[0].message);
          dispatch(error(message));
        } else {
          toast.error(e.message);
          dispatch(error(e.message));
        }
      });
  };
}

export function reset() {
  return (dispatch) => {
    dispatch(loading(false));
    dispatch(error(null));
  };
}
