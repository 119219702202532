/* eslint-disable import/extensions */
import {
  applyMiddleware,
  combineReducers,
  createStore
} from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import {
  connectRouter,
  routerMiddleware
} from 'connected-react-router';
// eslint-disable-next-line import/no-extraneous-dependencies
import { createBrowserHistory } from 'history';
import authReducer from '../Reducers/Auth';
import account from '../Generated/reducers/account';
import grade from '../Generated/reducers/grade';
import parent from '../Generated/reducers/parent';
import children from '../Generated/reducers/children';
import payments from '../Generated/reducers/payments';
import vouchers from '../Generated/reducers/vouchers';
import plans from '../Generated/reducers/plans';
import paymentMethods from '../Generated/reducers/paymentMethods';
import forgotPassword from '../Reducers/ForgotPassword';
import language from '../Generated/reducers/language';
import teacher from '../Generated/reducers/teacher';
import liveclass from '../Generated/reducers/liveclass';
// import { Jwt } from '../Actions/Jwt';

const history = createBrowserHistory();
export default () => {
  return createStore(
    combineReducers({
      router: connectRouter(history),
      auth: authReducer,
      account,
      grade,
      parent,
      children,
      payments,
      vouchers,
      plans,
      paymentMethods,
      forgotPassword,
      language,
      teacher,
      liveclass,

    }),
    composeWithDevTools(applyMiddleware(routerMiddleware(history), thunk))
  );
};
