import React from 'react';
import { Route } from 'react-router-dom';

export const TokenRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} component={(props) => {
    localStorage.removeItem('token');
    localStorage.removeItem('teacher');
    return (
      <Component {...props} />
    );
  }
  } />
);

export default TokenRoute;
