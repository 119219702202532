import { fetch } from '../../utils/dataAccess';

export function error(error) {
  return { type: 'PLANS_LIST_ERROR', error };
}

export function loading(loading) {
  return { type: 'PLANS_LIST_LOADING', loading };
}

export function success(retrieved) {
  return { type: 'PLANS_LIST_SUCCESS', retrieved };
}

export function list(perPage, pageNo, lang) {
  return dispatch => {
    dispatch(loading(true));
    dispatch(error(''));

    let headers = new Headers();
    headers.set('Accept-Language', lang);
    fetch(`plans?per_page=${perPage}&page=${pageNo}`, { headers }).then(response =>
        response
          .json()
          .then(retrieved => {
            dispatch(loading(false));
            dispatch(success(retrieved.data));
          })
      )
      .catch(e => {
        dispatch(loading(false));
        dispatch(error(e.message));
      });
  };
}
