import React from 'react';
import { Route, Redirect } from 'react-router-dom';

export const PublicRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} component={(props) => {
    if (localStorage.getItem('payload')) {
      if (localStorage.getItem('teacher')) {
        return <Redirect to="/teacher" />;
      }
      if (localStorage.getItem('child')) {
        return (<Redirect to="/doubt-clearing-chat" />);
      }
      return (<Redirect to="/account" />);
    }
    localStorage.clear();
    return (<Component {...props} />);
  }} />
);

export default PublicRoute;
