import { fetch } from '../../utils/dataAccess';

export const error = (error) => {
  return { type: 'VOUCHERS_SHOW_ERROR', error };
}

export const loading = (loading) => {
  return { type: 'VOUCHERS_SHOW_LOADING', loading };
}

export const success = (retrieved) => {
  return { type: 'VOUCHERS_SHOW_SUCCESS', retrieved };
}

export const retrieve = (values, lang) => {
  return dispatch => {
    dispatch(loading(true));

    let headers = new Headers();
    headers.set('Accept-Language', lang);

    return fetch(`vouchers/discount_preview`, { headers, method: 'post', body: JSON.stringify(values) })
      .then(response =>
        response
          .json()
          .then(retrieved => {
            dispatch(loading(false));
            dispatch(success(retrieved.data));
            dispatch(error(null));
          })
      )
      .catch(e => {
        dispatch(loading(false));
        dispatch(error(e.message));
        dispatch(success(false));
      });
  };
}

