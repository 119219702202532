/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import firebase from 'firebase/app';
import 'firebase/analytics';
import Input from '../../Components/Input/Input';
import Button from '../../Components/Button/Button';
import Loading from '../../Components/Loading/Loading';
import Modal from '../../Components/Modal/Modal';
import { APP_NAME } from '../../Constants/Constants';
import DropdownFormComponent from '../../Components/DropdownFormComponent/DropdownFormComponent';

// import { list as paymentMethodsActionList } from '../../Generated/actions/paymentMethods/list';
import { checkUsername as accountsCheckUsername } from '../../Generated/actions/account/create';
// import { list as plansActionList } from '../../Generated/actions/plans/list';

import './Signup.scss';
import Header from '../../Components/Header/Header';

import User from '../../Assets/Images/signup_user.png';
import Gender from '../../Assets/Images/signup_gender.png';
import School from '../../Assets/Images/signup_school.png';
import Avatar from '../../Assets/Images/signup_avatar.png';

import AvatarXav from '../../Assets/Images/signup_xav.png';
import AvatarSitta from '../../Assets/Images/signup_sitta.png';
import AvatarChaca from '../../Assets/Images/signup_chaca.png';
import AvatarJoji from '../../Assets/Images/signup_joji.png';
import AvatarRiri from '../../Assets/Images/signup_riri.png';

const Signup = () => {
  // States

  const analytics = firebase.analytics();

  const History = useHistory();

  const dispatch = useDispatch();

  const isLoading = useSelector((state) => state.payments.create.loading);

  const location = useSelector((state) => state.router.location);

  const language = useSelector((state) => state.language.change);
  const langData = language.lang ? language.lang.data : {};

  document.title = `${langData.signupButton} - ${APP_NAME}`;

  const voucherSelector = useSelector((state) => state.vouchers.show);
  const accountSelector = useSelector((state) => state.account.create);

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [stepBack, setStepBack] = useState();
  const genderOptions = [
    { label: langData.boy, value: 'boy' },
    { label: langData.girl, value: 'girl' },
  ];

  const avatarOptions = [
    { image: AvatarJoji, name: 'Joji', value: 5 },
    { image: AvatarChaca, name: 'Chaca', value: 3 },
    { image: AvatarRiri, name: 'Riri', value: 2 },
    { image: AvatarSitta, name: 'Sitta', value: 4 },
    { image: AvatarXav, name: 'Xav', value: 1 },
  ];

  const gradeOptions = [
    { label: 'SD 1', value: 0 },
    { label: 'SD 2', value: 1 },
    { label: 'SD 3', value: 2 },
    { label: 'SD 4', value: 3 },
    { label: 'SD 5', value: 4 },
    { label: 'SD 6', value: 5 },
  ];

  const [userData, setUserData] = useState({
    phone_number: location.state ? location.state.phone_number : '',
    email: location.state ? location.state.email : '',
    children: {
      fullname: '',
      school_name: '',
      grade_sorting_order: null,
      gender: '',
      item_id: 1,
    },
  });
  const [firstGradeSelection, setFirstGradeSelection] = useState();

  const [gradeModal, setGradeModal] = useState(false);
  const [modal, setModal] = useState(false);
  const [selectedAvatar, setSelectedAvatar] = useState(5);
  const [step, setStep] = useState('basic');

  // Effects
  useEffect(() => {
    if (location.state && (!location.state.phone_number && !location.state.email)) {
      History.push('/verification');
    }
  }, [location, History]);

  useEffect(() => {
    if (stepBack) {
      setStep('basic');
    }
  }, [stepBack]);

  // If list of payment methods is not present in redux
  // Call for api
  // useEffect(() => {
  //   dispatch(paymentMethodsActionList());
  // }, [dispatch]);

  // If list of plans subscription is not present in redux
  // Call for api
  // useEffect(() => {
  //   dispatch(plansActionList(10, 1, 'id'));
  // }, [dispatch]);

  useEffect(() => {
    voucherSelector.retrieved = null;
    voucherSelector.error = null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [History]);

  // Functions
  const onChange = (name, value, isChildren) => {
    let object;

    if (isChildren === true) {
      if (name === 'grade_sorting_order') {
        if (value !== 3) {
          setFirstGradeSelection(value);
          // eslint-disable-next-line no-param-reassign
          value = null;
          setGradeModal(true);
        }
      }
      const children = { ...userData.children, [name]: value };
      object = { ...userData, children };
      if (accountSelector.error) {
        accountSelector.error = accountSelector.error.filter((fields) => fields.field !== `children.0.${name}`);
      }
    } else {
      object = { ...userData, [name]: value };
      if (accountSelector.error) {
        accountSelector.error = accountSelector.error.filter((fields) => fields.field !== name);
      }
    }

    setUserData(object);

    if (name === 'referral_code' && value === '') {
      voucherSelector.retrieved = null;
      voucherSelector.error = null;
    }
  };

  const onContinueClick = () => {
    if (step.includes('basic')) {
      return setStep('username');
    }

    if (step.includes('username')) {
      setLoading(true);
      const updatedUserData = {
        ...userData,
        children: {
          ...userData.children,
          first_time_grade_sorting_order: firstGradeSelection,
        },
      };
      dispatch(
        accountsCheckUsername(updatedUserData, langData, History, analytics, setErrorMessage, setStep, setLoading),
      );
    }
  };

  // Render Functions
  const renderLoginModal = () => {
    return (
      <Modal setModal={setModal}>
        <div className="modal-content">
          <h3>{langData.textModal}</h3>
          <p>{langData.pModal}</p>
          <Button
            block
            type="secondary"
            // type={ !showSubmitButton ? 'disabled' : ''}
            onClick={() => History.goBack()}
          >
            {langData.imsure}
          </Button>
          <Button
            block
            // type={ !showSubmitButton ? 'disabled' : ''}
            onClick={() => setModal(false)}
          >
            {langData.nowait}
          </Button>
        </div>
      </Modal>
    );
  };

  const renderGradeModal = () => {
    return (
      <Modal setModal={setModal}>
        <div className="modal-content">
          <h4>
            Konten tidak tersedia untuk SD Anda saat ini. Jika Anda mau, Anda bisa bermain dengan konten Kelas SD 4.
          </h4>
          <Button block onClick={() => setGradeModal(false)}>
            Okay!
          </Button>
        </div>
      </Modal>
    );
  };

  const renderSignUpForm = () => {
    return (
      <div className="signup-page-form">
        <div className="signup-page-form-input">
          <Input
            iconLabel={User}
            customLabel={langData.nameLabel}
            placeholder={langData.nameChildPlaceholder}
            onChange={(e) => onChange('fullname', e.target.value, true)}
            value={userData.children.fullname}
            errorMessage={
              accountSelector.error &&
              accountSelector.error.find((fields) => fields.field === 'children.0.fullname') &&
              accountSelector.error.find((fields) => fields.field === 'children.0.fullname').message
            }
          />
        </div>
        <div className="signup-page-form-input">
          <DropdownFormComponent
            iconLabel={Gender}
            customLabel={langData.gender}
            placeholder={langData.genderChildPlaceholder}
            options={genderOptions}
            onChange={(value) => onChange('gender', value, true)}
            value={userData.children.gender}
            errorMessage={
              accountSelector.error &&
              accountSelector.error.find((fields) => fields.field === 'children.0.gender') &&
              accountSelector.error.find((fields) => fields.field === 'children.0.gender').message
            }
          />
        </div>
        <div className="signup-page-form-input">
          <Input
            iconLabel={School}
            customLabel={langData.schoolPlaceholder}
            placeholder={langData.schoolChildPlaceholder}
            onChange={(e) => onChange('school_name', e.target.value, true)}
            value={userData.children.school_name}
          />
          <DropdownFormComponent
            placeholder={langData.gradeChildPlaceholder}
            options={gradeOptions}
            onChange={(value) => onChange('grade_sorting_order', value, true)}
            value={userData.children.grade_sorting_order}
          />
        </div>
      </div>
    );
  };

  const renderUsernameWithAvatar = () => {
    return (
      <div>
        <div className="signup-page-form">
          <div className="signup-page-form-input">
            <Input
              iconLabel={User}
              customLabel={langData.username}
              placeholder={langData.usernameField}
              onChange={(e) => onChange('username', e.target.value, true)}
              value={userData.children.username}
              errorMessage={errorMessage && errorMessage}
            />
          </div>
        </div>
        <div className="avatar-wrapper">
          <div className="custom-label">
            <div className="custom-label-wrapper-icon">
              <img src={Avatar} alt="avatar" />
            </div>
            Avatar
          </div>
          <div className="avatars">
            {avatarOptions &&
              avatarOptions.map((avatar) => (
                <div
                  key={avatar.value}
                  className={`avatars-wrapper ${selectedAvatar === avatar.value ? 'active' : ''}`}
                  onClick={() => {
                    setSelectedAvatar(avatar.value);
                    onChange('item_id', avatar.value, true);
                  }}
                >
                  <img src={avatar.image} alt={avatar.name} />
                  <span> {avatar.name} </span>
                </div>
              ))}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="page-container">
      {modal ? renderLoginModal() : ''}
      {gradeModal ? renderGradeModal() : ''}
      {isLoading && <Loading />}
      <Header text={langData.signUp} back={true} backFunc={step.includes('basic') ? setModal : setStepBack} />

      <div className="body-content" style={{ marginTop: '30px' }}>
        {step.includes('username') ? renderUsernameWithAvatar() : renderSignUpForm()}

        <div className="signup-page-submit">
          <Button block type={loading ? 'disabled' : ''} onClick={() => onContinueClick()}>
            {langData.continue}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Signup;
