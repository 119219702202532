import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import firebase from 'firebase/app';
import 'firebase/analytics';

import Navbar from '../../Components/Navbar/Navbar';
import Button from '../../Components/Button/Button';
import Loading from '../../Components/Loading/Loading';
import Modal from '../../Components/Modal/Modal';
import { APP_NAME } from '../../Constants/Constants';
import PlanCard from '../../Components/PlanCard/PlanCard';
import { addToEdit as childrenActionAddToEdit } from '../../Generated/actions/children/update';
import { unSubscribe as paymentsActionUnsubscribe } from '../../Generated/actions/payments/create';
import { updateTags } from '../../Generated/utils/OneSignal';
import { sendEvent } from '../../Generated/actions/event/create';
import { retrieve as plansActionShow } from '../../Generated/actions/plans/show';

import './Subscription.scss';

const Subscription = () => {
  const analytics = firebase.analytics();
  const History = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const isLoading = useSelector((state) => state.payments.create.loading);
  const plansSelector = useSelector((state) => state.plans.show);
  const language = useSelector((state) => state.language.change);
  const [profileToUpgrade, setProfileToUpgrade] = useState({});
  const langData = language.lang ? language.lang.data : {};

  const [modal, setModal] = useState(false);

  document.title = `${langData.subscriptionStatus} - ${APP_NAME}`;

  useEffect(() => {
    if (profileToUpgrade.id) {
      dispatch(
        sendEvent({
          activity_type: 'OPEN_SCREEN',
          title: 'open_screen',
          screen_name: 'SubscriptionStatus',
          is_admin: 'No',
          payload: {
            source: 'account_login',
            child_id: profileToUpgrade.id,
          }
        })
      );
    }
  }, [profileToUpgrade, dispatch]);

  const onEditClick = (account) => {
    dispatch(childrenActionAddToEdit(account));
    History.push(`/edit-child/${account.id}`);
  };

  useEffect(() => {
    setProfileToUpgrade(location.state.account);
  }, [profileToUpgrade, langData, location.state]);

  useEffect(() => {
    if (profileToUpgrade.subscription) {
      dispatch(plansActionShow(`plans/${profileToUpgrade.subscription.plan_id}`));
    }
  }, [dispatch, profileToUpgrade]);

  const onUpgradeClick = (account) => {
    analytics.logEvent('child_selection', { user_id: account.id });
    updateTags(account.id, { payment_status: 'child_selection' });

    dispatch(childrenActionAddToEdit(account));
    if (account.subscription.is_free) {
      History.push(`/upgrade/${account.id}`);
    } else {
      History.push(`/subscription-status/${account.id}`);
    }
  };

  const onUnsubscribeClick = (account) => {
    dispatch(paymentsActionUnsubscribe(
      {
        user_id: account.id,
      },
      History,
    ));
  };

  const onSubscribeClick = (account) => {
    History.push(`/upgrade/${account.id}`);
  };

  const renderModal = () => {
    return (
      <Modal setModal={setModal}>
        <div style={{ padding: '40px' }}>
          <h2>{langData.subscriptionModalHeader}</h2>
          <p>{langData.subscriptionModalBody}</p>

          <div className="modal-subscription-button">
            <Button
              onClick={() => onUnsubscribeClick(profileToUpgrade)}
              type="secondary"
            >
              {langData.subscriptionSure}
            </Button>
            <Button
              onClick={() => setModal(false)}
            >
              {langData.subscriptionUnsure}
            </Button>
          </div>
        </div>
      </Modal>
    );
  };

  const renderPlan = () => {
    return (
      <div>
        <span className='body-content-heading' >
          {langData.subscriptionTypes}
        </span>

        <div className="padding" style={{ marginBottom: '200px' }}>
          <div className='upgrade-page-subscription-premium'>
            {plansSelector.retrieved && (
              <div
                key={plansSelector.retrieved.id}
                className={`upgrade-page-subscription-premium-plan ${plansSelector.retrieved.color}`}>
                <div className={'heading'} >
                  <span>Pintar Plan</span>
                  <div>
                    <span>Rp {plansSelector.retrieved.price.toLocaleString()}</span>
                  </div>
                </div>
                <div className={'points'} >
                  <span className={'point'} >
                    <span className={'tick'}>
                      <svg width="11" height="9" viewBox="0 0 11 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M1.32007 4.072L3.91207 7.23999L9.96007 1.48"
                          strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    </span>
                    {langData.fullAccess}
                  </span>
                  <span className={'point'} >
                    <span className={'tick'}>
                      <svg width="11" height="9" viewBox="0 0 11 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M1.32007 4.072L3.91207 7.23999L9.96007 1.48"
                          strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    </span>
                    {langData.unlimited}
                  </span>
                  <span className={'point'} >
                    <span className={'tick'}>
                      <svg width="11" height="9" viewBox="0 0 11 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M1.32007 4.072L3.91207 7.23999L9.96007 1.48"
                          strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    </span>
                    {langData.saved}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderHeader = () => {
    return (
      <div className='body-content' >
        <span className='body-content-heading' >
          <i
            className='fal fa-arrow-left body-content-heading-back-arrow'
            onClick={() => History.goBack()}
          />
          {langData.subscriptionStatus}
        </span>

        <div className="padding">
          {profileToUpgrade.id ? (
            <PlanCard
              account={profileToUpgrade}
              onEditClick={onEditClick}
              upgradePlan={plansSelector.retrieved ? {} : {}}
              onUpgradeClick={onUpgradeClick}
              onlyShow={true}
            />
          ) : ''}
        </div>

        <div className='border-section'>&nbsp;</div>

        {renderPlan()}

        <div className="subscription-button">
          {profileToUpgrade.latest_payment && profileToUpgrade.latest_payment.payment_method === 'PULSA' ? (
            <Button
              onClick={() => setModal(true)}
              type="secondary"
            >
              {langData.subscriptionUnsubscribe}
            </Button>
          ) : (
            <Button
              onClick={() => onSubscribeClick(profileToUpgrade)}
            >
              {langData.subscriptionExtends}
            </Button>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className='page-container' >
      {isLoading && <Loading />}
      <Navbar />
      {renderHeader()}
      {modal ? renderModal() : ''}
    </div>
  );
};

export default Subscription;
