import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import TypeProps from 'prop-types';

import './Header.scss';
import Logo from '../../Assets/Images/Logo.png';
import Back from '../../Assets/Images/arrow_back.png';

const Header = (props) => {
  const History = useHistory();
  const language = useSelector((state) => state.language.change);
  const langData = language.lang ? language.lang.data : {};

  const {
    text,
    back = false,
    backFunc = () => History.goBack()
  } = props;

  return (
    <div className='header' >
        <span className={`header-heading ${text ? 'small' : ''}`} >
            {text || langData.jumboText}
        </span>
        <img className='header-img' src={Logo} alt="logo" />

        {back
          ? <img className='header-img-arrow'
                 src={Back}
                 alt="back"
                 onClick={() => backFunc(true)}
            />
          : ''}
    </div>
  );
};

Header.propTypes = {
  text: TypeProps.string,
  back: TypeProps.bool,
};

export default Header;
