import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import moment from 'moment-timezone';
import 'firebase/analytics';
import querystring from 'querystring';
import Calendar from 'react-calendar';
import Navbar from '../../Components/Navbar/Navbar';
import Button from '../../Components/Button/Button';
import { APP_NAME } from '../../Constants/Constants';
import CalendarImage from '../../Assets/Images/calendar.png';
import Kids from '../../Assets/Images/kids.png';
import KidWhites from '../../Assets/Images/kids_white.png';

import { retrieve as accountActionRetrieve } from '../../Generated/actions/account/show';
import { retrieve as teacherActionRetrieve } from '../../Generated/actions/teacher/show';
import { retrieve as liveClassActionRetrieve } from '../../Generated/actions/liveclass/list';
import { retrieve as waitingListActionRetrieve } from '../../Generated/actions/liveclass/waitinglist';

import './TeacherPage.scss';

moment.tz.setDefault('Asia/Jakarta');

const TeacherPage = () => {
  // States

  const History = useHistory();

  const dispatch = useDispatch();

  // const accountDetails = useSelector((state) => state.account.show);
  const teacherDetails = useSelector((state) => state.teacher.show);
  const liveClassList = useSelector((state) => state.liveclass.list);
  const waitingList = useSelector((state) => state.liveclass.waitinglist);

  const language = useSelector((state) => state.language.change);
  const langData = language.lang ? language.lang.data : {};
  const [modal, setModal] = useState(false);
  const [liveClassModalData, setLiveClassModalData] = useState();
  const [waitingListData, setWaitingListData] = useState([]);

  const [isFilter, setIsFilter] = useState(false);
  const [dateModal, setDateModal] = useState(false);
  const [subjectFilter, setSubjectFilter] = useState('');
  const [dateFilter, setDateFilter] = useState('');

  // Effects
  useEffect(() => {
    document.title = `${langData.teacherPage} - ${APP_NAME}`;
  });

  useEffect(() => {
    dispatch(accountActionRetrieve());
    dispatch(teacherActionRetrieve());
  }, [dispatch]);

  useEffect(() => {
    if (teacherDetails.retrieved) {
      const date = dateFilter === '' ? '' : moment(dateFilter).format('YYYY-M-DD');

      const params = querystring.stringify({
        orderBySchedule: 'start',
        orderSchedule: 1,
        only_active: 1,
        subject_slug: subjectFilter,
        start: date
      });
      dispatch(liveClassActionRetrieve(params));
    }
  }, [dispatch, teacherDetails, subjectFilter, dateFilter]);

  useEffect(() => {
    if (waitingList.retrieved) {
      setWaitingListData(waitingList.retrieved);
    }
  }, [dispatch, modal, waitingList]);

  const getWaitingList = (id) => {
    dispatch(waitingListActionRetrieve(id));
  };

  const statusOnGoing = (start, end) => {
    const startMoment = moment(start);
    const endMoment = moment(end);
    if (moment().isBetween(startMoment, endMoment)) {
      return <div className="ongoing">On Going</div>;
    }
    return <div></div>;
  };

  const renderModal = () => {
    return (
      <div className="modal-wrapper">
        <div className="modal-waiting-list">
          <div className="modal-header">
            <div className='modal-heading'>
              {liveClassModalData.topic.translations[0].name}
            </div>
            <div className='modal-small'>
              <img src={KidWhites} alt="total-kids" style={{ marginRight: '10px', marginBottom: '5px' }} />
              {liveClassModalData.user_joined}
            </div>
          </div>
          <div className="modal-body">
            <div className="waiting-list">
              {waitingListData.length ? (
                <ol>
                  {waitingListData.map((list) => {
                    return (
                      <li>{list.user ? list.user.nick_name : list.user_id}</li>
                    );
                  })
                  }
                </ol>
              ) : (<center>Reservasi Kelas masih kosong</center>)}
            </div>
            <div className="modal-footer">
              <Button block>
                {langData.liveClassPreview}
              </Button>
              <Button onClick={() => setModal(false)} type="secondary" block>
                Tutup
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  // Render Functions
  const renderHeader = () => {
    if (isFilter) {
      return (
        <div className='body-content-heading' >
          <i
            className='fal fa-arrow-left body-content-heading-back-arrow'
            onClick={() => setIsFilter(false)}
          />
        </div>
      );
    }
    return (
      <div className='teacher-page-header' >
        <span className="teacher-page-header-small">{langData.teacherWelcome}</span>
        <span className='teacher-page-header-heading'>{teacherDetails.retrieved
          && teacherDetails.retrieved.full_name}</span>
      </div>
    );
  };

  const renderLiveClass = (liveClassData) => {
    return (
      <div className='live-class' key={liveClassData.id}>
        {statusOnGoing(liveClassData.start, liveClassData.end)}
        <div className='heading'>
          {liveClassData.topic.translations[0].name}
        </div>
        <div className='small'>
          {liveClassData.topic.category.subject.translations[1].name}
        </div>
        <div className='border'></div>
        <div className='calendar'>
          <img src={CalendarImage} alt="time-live-classes" />
          {moment.utc(liveClassData.start, 'YYYY-MM-DD H:m:s').format('h:mm a, MMMM Do, YYYY')}
        </div>
        <div className='kids'>
          <img src={Kids} alt="total-kids" />
          {liveClassData.user_joined}
        </div>
        <br />
        <br />
        <Button block onClick={() => {
          getWaitingList(liveClassData.id);
          setLiveClassModalData(liveClassData);
          setModal(true);
        }} >
          Lihat Peserta
        </Button>
        <br />
        <br />
        {liveClassData.is_finished ? (
          <Button type="secondary" block onClick={() => History.push(`/live-class/${liveClassData.id}`)} >
            Kelas telah berahkir
          </Button>
        ) : (
          <Button block onClick={() => History.push(`/live-class/${liveClassData.id}`)} >
            {langData.liveClassPreview}
          </Button>
        )}
      </div>
    );
  };

  const SUBJECTS = [
    {
      title: 'MATHEMARKET',
      props: {
        onClick: () => setSubjectFilter('math'),
        key: 'math'
      }
    },
    {
      title: 'SCIENCE PARK',
      props: {
        onClick: () => setSubjectFilter('science'),
        key: 'science'
      }
    },
    {
      title: 'ALL',
      props: {
        onClick: () => setSubjectFilter(''),
        key: 'all'
      }
    },
  ];

  const renderFilter = () => {
    if (isFilter) {
      return (
        <>
          <div className='teacher-page-filter'>
            <p>Filter Berdasarkan Subjek</p>
            <div className='teacher-page-btn-filter'>
              <Button type='dropdown' dropdownItem={SUBJECTS}>
                {subjectFilter === '' ? 'Pilih Subjek' : subjectFilter}
              </Button>
            </div>
          </div>
          <div className='teacher-page-filter'>
            <p>Filter Berdasarkan Tanggal</p>
            <div className='teacher-page-btn-filter'>
              <Button onClick={() => setDateModal(true)}>
                {dateFilter === '' ? 'Pilih Tanggal' : moment(dateFilter).format('DD MMMM YYYY')}
              </Button>
            </div>
          </div>
        </>
      );
    }

    return (
      <>
        <span className='teacher-page-header-heading heading-filter' >{langData.liveClassList}</span>
        <div className='teacher-page-btn-filter'>
          <Button onClick={() => setIsFilter(true)}>
            Filter
          </Button>
        </div>
      </>
    );
  };

  const onChange = (value) => {
    setDateFilter(new Date(value));
    setDateModal(false);
  };

  const onClear = () => {
    setDateFilter('');
    setDateModal(false);
  };

  const DateModal = () => {
    return (
      <Modal centered show={dateModal} onHide={() => setDateModal(false)}>
        <Calendar
          onChange={onChange}
          value={dateFilter}
          className='teacher-page-calendar'
        />
        <Button onClick={onClear} type='danger'>
          Clear
          </Button>
      </Modal>
    );
  };

  return (
    <div className='page-container teacher-page' >
      <Navbar />
      <div className='body-content' >
        {renderHeader()}
        <DateModal />
        <div className='teacher-page-border' />
        <div className='teacher-page-content'>
          {renderFilter()}
          {(liveClassList.retrieved && liveClassList.retrieved.length && liveClassList.retrieved.map((liveclass) => {
            return renderLiveClass(liveclass);
          })) || <p>Tidak ada kelas langsung saat ini</p>}
        </div>
      </div>
      {modal ? renderModal() : ''}
    </div>
  );
};

export default TeacherPage;
