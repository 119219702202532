import React, { useEffect } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Navbar from '../../Components/Navbar/Navbar';
import AlbyIllustration from '../../Assets/Images/alby-illustration.png';
import Loading from '../../Components/Loading/Loading';
import { sendEvent } from '../../Generated/actions/event/create';
import { APP_NAME } from '../../Constants/Constants';

import { list as paymentsActionList } from '../../Generated/actions/payments/list';

import './BillingHistory.scss';

const BillingHistory = () => {
  // States

  const History = useHistory();

  const dispatch = useDispatch();

  const paymentsListSelector = useSelector((state) => state.payments.list);

  const isLoading = useSelector((state) => state.payments.list.loading);

  const language = useSelector((state) => state.language.change);
  const langData = language.lang ? language.lang.data : {};

  // Effects
  useEffect(() => {
    document.title = `${langData.billingHeader} - ${APP_NAME}`;
  });

  useEffect(() => {
    dispatch(
      sendEvent({
        activity_type: 'OPEN_SCREEN',
        title: 'open_screen',
        screen_name: 'BillingHistory',
        is_admin: 'No',
        payload: {
          source: 'account_login',
        }
      })
    );
  }, [dispatch]);

  useEffect(() => {
    dispatch(paymentsActionList(10, 1));
  }, [dispatch]);

  return (
    <div className='page-container' >
      {isLoading && <Loading />}
      <Navbar />
      <div className='body-content' >
        <span className='body-content-heading' >
          <i
            className='fal fa-arrow-left body-content-heading-back-arrow'
            onClick={() => History.goBack()}
          /> {langData.billingHeader}
        </span>

        {paymentsListSelector.retrieved
          && Object.keys(paymentsListSelector.retrieved).length > 0
          && Object.keys(paymentsListSelector.retrieved).map((month) => (
            <React.Fragment key={month} >
              <div className='billing-history-month' >
                {moment(month.substring(4, 6), 'MM').format('MMMM')}{' '}{month.substring(0, 4)}
              </div>

              {paymentsListSelector.retrieved[month].map((billing) => (
                <div className='billing-history-billing' key={billing.id} >
                  <span className='billing-history-billing-date' >
                    {moment(billing.paid_at).format('DD MMM YYYY')}
                  </span>

                  <div className='billing-history-billing-reference' >
                    <span>{langData.referenceId} </span>
                    <span>{billing.order_id}</span>
                  </div>
                  <div className='billing-history-billing-content' >
                    <span>Pintar Plan - {langData.paymentFor} {billing.user.nick_name}</span>
                    <span>Rp{billing.paid_amount.toLocaleString()}</span>
                  </div>
                </div>
              ))}
            </React.Fragment>
          ))
        }

        {paymentsListSelector.retrieved
          && paymentsListSelector.retrieved.length === 0
          && (
            <div className='billing-history-no-payment' >
              <img src={AlbyIllustration} alt="" className='billing-history-no-payment-img' />
              <span className='billing-history-no-payment-text' >
                {langData.noBilling}
              </span>
            </div>
          )}

      </div>
    </div>
  );
};

export default BillingHistory;
