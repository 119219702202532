import { fetch } from '../../utils/dataAccess';

export const error = (error) => {
  return { type: 'LIVE_CLASS_SHOW_ERROR', error };
}

export const loading = (loading) => {
  return { type: 'LIVE_CLASS_SHOW_LOADING', loading };
}

export const success = (retrieved) => {
  return { type: 'LIVE_CLASS_SHOW_SUCCESS', retrieved };
}

export const retrieve = (id) => {
  return dispatch => {
    dispatch(loading(true));

    return fetch(`live_class_schedules/${id}`)
      .then(response => 
        response
          .json()
          .then(retrieved => {
            dispatch(loading(false));
            dispatch(success(retrieved.data));
          })
      )
      .catch(e => {
        dispatch(loading(false));
        dispatch(error(e.message));
      });
  };
}
