import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import firebase from 'firebase/app';
import 'firebase/analytics';

import Navbar from '../../Components/Navbar/Navbar';
import Input from '../../Components/Input/Input';
import Button from '../../Components/Button/Button';
import Loading from '../../Components/Loading/Loading';
import { APP_NAME } from '../../Constants/Constants';
import {
  pulsaPayment as paymentsActionCreate,
  verifyOTP as paymentsVerifyOTP,
} from '../../Generated/actions/payments/create';
import { retrieve as accountActionRetrieve } from '../../Generated/actions/account/show';
import { sendEvent } from '../../Generated/actions/event/create';

import telkomselLogo from '../../Assets/Images/telkomsel.png';
import './TelkomselPayment.scss';

const TelkomselPayment = () => {
  const analytics = firebase.analytics();
  const History = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const isLoading = useSelector((state) => state.payments.create.loading);
  const error = useSelector((state) => state.payments.create.error);
  const language = useSelector((state) => state.language.change);
  const langData = language.lang ? language.lang.data : {};

  const accountDetails = useSelector((state) => state.account.show);
  const [step, setStep] = useState('phone_number');
  const [profileToUpgrade, setProfileToUpgrade] = useState({});
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [otp, setOtp] = useState('');
  const [otpError, setOtpError] = useState(null);

  useEffect(() => {
    setProfileToUpgrade(location.state.profileToUpgrade);
    setSelectedPaymentMethod(location.state.selectedPaymentMethod);
    setSelectedPlan(location.state.selectedPlan);
  }, [profileToUpgrade, selectedPaymentMethod, selectedPlan, langData, location.state]);

  useEffect(() => {
    if (step.includes('phone_number')) {
      document.title = `${langData.paymentHeader} - ${APP_NAME}`;
    } else {
      document.title = `${langData.paymentOTP} - ${APP_NAME}`;
    }
  }, [step, langData]);

  useEffect(() => {
    if (profileToUpgrade.id) {
      dispatch(
        sendEvent({
          activity_type: 'OPEN_SCREEN',
          title: 'open_screen',
          screen_name: 'Telkomsel Payment Page',
          is_admin: 'No',
          payload: {
            source: 'account_login',
            child_id: profileToUpgrade.id,
          }
        })
      );
    }
  }, [profileToUpgrade, dispatch]);

  const setThePhoneNumber = (value) => {
    // Remove +62 from users input
    const number = value.replace('+62', '');
    setPhoneNumber(number);
  };

  useEffect(() => {
    dispatch(accountActionRetrieve());
  }, [dispatch]);

  useEffect(() => {
    if (accountDetails.retrieved && accountDetails.retrieved.phone_number) {
      setThePhoneNumber(accountDetails.retrieved.phone_number);
    }
  }, [accountDetails.retrieved]);

  const onContinueClick = () => {
    dispatch(paymentsActionCreate(
      {
        user_id: profileToUpgrade.id,
        payment_method_id: selectedPaymentMethod ? selectedPaymentMethod.id : '',
        voucher_code: profileToUpgrade.referral_code,
        plan_id: selectedPlan.id,
        phone_number: `+62${phoneNumber}`
      },
      History,
      analytics,
      setStep
    ));

    dispatch(
      sendEvent({
        activity_type: 'ACCOUNT_LOGIN_ACTION',
        title: 'Account Login Action',
        type: 'TelkomselOtpAttempt',
        is_admin: 'No',
        payload: {
          source: 'account_login',
          user_id: profileToUpgrade.id,
          voucher_code: profileToUpgrade.referral_code,
          subscription_type: selectedPlan.name,
          selectedPlan
        },
        child_id: profileToUpgrade.id
      })
    );

    analytics.logEvent('continue_payment_clicked', {
      user_id: profileToUpgrade.id,
      payment_method: selectedPaymentMethod ? selectedPaymentMethod.payment_channel : null,
      subscription_type: selectedPlan.name,
      voucher_code: profileToUpgrade.referral_code,
    });
  };

  const onVerifyClick = () => {
    dispatch(paymentsVerifyOTP(
      {
        otp
      },
      History,
      setOtpError
    ));
  };

  const renderPhoneNumber = () => {
    return (
      <div className='body-content' >
        <span className='body-content-heading' >
          <i
            className='fal fa-arrow-left body-content-heading-back-arrow'
            onClick={() => History.goBack()}
          />
          {langData.paymentHeader}
        </span>

        <div className='border-section'>&nbsp;</div>

        <div className='telkomsel-payment-input' >
          <div className='telkomsel-payment-input-heading' >
            <span>{langData.paymentPhoneNumber}</span>
          </div>
          <div className='telkomsel-payment-input-form'>
            <div className='wrapper'>
              <Input
                placeholder={langData.paymentField}
                onChange={(e) => setThePhoneNumber(e.target.value)}
                value={phoneNumber}
                autoFocus={true}
                style={{ textAlign: 'left' }}
              />
              <img src={telkomselLogo} alt="Telkomsel" />
              {error === null
                ? ''
                : <span> {error} </span>
              }
            </div>

            <Button
              onClick={() => onContinueClick()}>{langData.continue}
            </Button>
          </div>

        </div>
      </div>
    );
  };

  const renderVerification = () => {
    return (
      <div className='body-content' >
        <span className='body-content-heading' >
          <i
            className='fal fa-arrow-left body-content-heading-back-arrow'
            onClick={() => History.goBack()}
          />
          {langData.paymentVerification}
        </span>

        <div className='border-section'>&nbsp;</div>

        <div className='telkomsel-payment-input' >
          <div className='telkomsel-payment-input-heading' >
            <span>{langData.paymentOTP}</span>
          </div>
          <div className='telkomsel-payment-input-form' >
            <div className='wrapper-otp'>
              <Input
                placeholder={langData.paymentOTPField}
                onChange={(e) => setOtp(e.target.value)}
                autoFocus={true}
                value={otp}
                style={{ textAlign: 'left' }}
              />
              {otpError === null
                ? ''
                : <span> {otpError.message} </span>
              }
            </div>

            <div className="wrapper-otp-button">
              <span>{langData.resendOTP} <b onClick={() => onContinueClick()}>{langData.resend}</b></span>
              <Button
                onClick={() => onVerifyClick()}>{langData.continue}
              </Button>
            </div>
          </div>

        </div>
      </div>
    );
  };

  return (
    <div className='page-container' >
      {isLoading && <Loading />}
      <Navbar />
      {step.includes('phone_number')
        ? renderPhoneNumber()
        : renderVerification()
      }
    </div>
  );
};

export default TelkomselPayment;
