import { combineReducers } from 'redux';
import { bahasaMessages, englishMessages } from '../../../Utils/Language/messages';

export function lang(state = {
  lang: 'id',
  data: bahasaMessages
}, action) {
  switch (action.type) {
    case 'CHANGE_LANGUAGE':
      return {
        lang: action.lang,
        data: action.lang === 'id' ? bahasaMessages : englishMessages
      };

    default:
      return state;
  }
}

export default combineReducers({ lang });
