/* eslint-disable */
import * as jwtDecode from 'jwt-decode';
import axios from 'axios';
import { fetch } from '../Generated/utils/dataAccess';
import { LOGIN_URL, LOGIN_LINK_URL, LOGIN_LINK_URL_LIVE_CHAT, USER_URL, OTP_COUNTDOWN, ROCKET_CHAT } from '../Constants/Constants';
import { toast } from 'react-toastify';
import { encryptData } from '../Utils/Helper/helpers';

export const isError = (value) => ({
  type: 'IS_ERROR',
  payload: value
});

export const isLoading = (bool) => ({
  type: 'IS_LOADING',
  payload: bool,
})

export const errorMessage = (string) => ({
  type: 'ERROR_MESSAGE',
  payload: string,
})

export const signIn = (usernameAndPassword, History) => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    try {
      const lang = localStorage.getItem('language') || 'id';
      const { data: { access_token, id } } = await axios.post(LOGIN_URL, usernameAndPassword);
      if (access_token) {
        dispatch(isLoading(false));
        localStorage.setItem('token', access_token);
        await axios.put(`${USER_URL}/${id}`, { lang });
        return History.push('/account');
      }
      return new Error();
    } catch (error) {
      dispatch(isLoading(false));
      return dispatch(isError(true));
    }
  };
};

export const signInOTP = (values, setStep, setCountdown) => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    if (values.email) { 
      delete values.otp_method;
    }
    return fetch('send_otp_login', { method: 'post', body: JSON.stringify(values) })
      .then(response => {
        dispatch(isLoading(false));

        return response.json();
      })
      .then(retrieved => {
        dispatch(errorMessage(''));
        setStep('verification');
        setCountdown(OTP_COUNTDOWN);
        console.log(retrieved);
      })
      .catch(e => {
        dispatch(isLoading(false));
        if (typeof e.message === 'string') {
          dispatch(errorMessage(e.message));
        } else {
          dispatch(errorMessage(JSON.parse(e.message).find((error) => error.field === 'phone_number').message));
        }
      });
  }
}

export const verifySignInOTP = (values, History) => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    return fetch('verify_otp_login', { method: 'post', body: JSON.stringify(values) })
      .then(response => {
        dispatch(isLoading(false));

        return response.json();
      })
      .then(retrieved => {
        const lang = 'id';
        if (retrieved.access_token) {
          dispatch(isLoading(false));
          localStorage.setItem('token', retrieved.access_token);
          if (retrieved.is_teacher) {
            localStorage.setItem('teacher', true);
            localStorage.setItem('payload', encryptData({role: 'teacher'}));
            axios.put(`${USER_URL}/${retrieved.id}`, { lang });
            return History.push('/teacher');
          } else {
            localStorage.setItem('payload', encryptData({role: 'parent'}));
          }

          axios.put(`${USER_URL}/${retrieved.id}`, { lang });
          return History.push('/account');
        }
        return new Error();
      })
      .catch(e => {
        dispatch(isLoading(false));
        dispatch(errorMessage(e.message));
      });
  }
}

export const signUpOTP = (values, setStep, setCountdown) => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    if (values.email) { 
      delete values.otp_method;
    }
    return fetch('send_otp_register', { method: 'post', body: JSON.stringify(values) })
      .then(response => {
        dispatch(isLoading(false));

        return response.json();
      })
      .then(retrieved => {
        dispatch(errorMessage(''));
        setStep('verification');
        setCountdown(OTP_COUNTDOWN);
        console.log(retrieved);
      })
      .catch(e => {
        dispatch(isLoading(false));
        if (typeof e.message === 'string') {
          dispatch(errorMessage(e.message));
        } else {
          dispatch(errorMessage(JSON.parse(e.message).find((error) => error.field === 'phone_number').message));
        }
      });
  }
}

export const verifySignUpOTP = (values, History, redirect = true) => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    return fetch('verify_otp_register', { method: 'post', body: JSON.stringify(values) })
      .then(response => {
        dispatch(isLoading(false));

        return response.json();
      })
      .then(retrieved => {
        const lang = 'id';
        if (retrieved.success) {
          dispatch(isLoading(false));
          if (redirect) {
            return History.push({
              pathname: '/signup',
              state: {
                phone_number: values.phone_number,
                email: values.email
              }
            });
          }
          return true;
        }
        return new Error();
      })
      .catch(e => {
        dispatch(isLoading(false));
        dispatch(errorMessage(e.message));
      });
  }
}

export const signInWithTokenLink = (values, History, langData) => {
  return async (dispatch) => {
    try {
      localStorage.removeItem('token');
      const { data: { access_token, child_count, child_first_id } } = await axios.post(LOGIN_LINK_URL, values);
      if (access_token) {
        localStorage.setItem('token', access_token);

        const tokenArr = values.token.split(':');
        let child_id = null;
        if (isNaN(tokenArr[1]) === false) {
          child_id = tokenArr[1];
        }

        localStorage.setItem('payload', encryptData({role: 'parent'}));

        if (child_id !== null) {
          return History.push(`/upgrade/${child_id}`);
        } else {
          return History.push('/account');
        }
      }
      return new Error();
    } catch (error) {
      console.log(error.message);
      toast.error(langData.tokenExpired);
      return History.push('/');
    }
  };
};

export const signInForLiveChat = (token, History, langData) => {
  return async (dispatch) => {
    try {
      localStorage.removeItem('token');
      localStorage.removeItem('teacher');
      const { data: { access_token } } = await axios.post(LOGIN_LINK_URL_LIVE_CHAT, token);
      if (access_token) {
        localStorage.setItem('token', access_token);
      }

      localStorage.setItem('payload', encryptData({role: 'child'}));
      return History.push({
        pathname: '/doubt-clearing-chat',
        state: {
          token: token.token.split(':')[2]
        }
      });
    } catch (error) {
      toast.error(langData.tokenExpired);
      return History.push('/');
    }
  };
};

export const createLiveChatAccount = (values, History) => {
  return async (dispatch) => {
    try {
      const data = await axios({
        method: 'POST',
        url: `${ROCKET_CHAT}/api/v1/users.register`,
        data: values
      });

      localStorage.setItem('live-chat-account', data);
      window.location.reload();
    } catch (error) {
    }
  }
}

export function refreshToken(dispatch) {
  dispatch(loading(true));
  const value = { refresh_token: localStorage.getItem('refresh_token') };
  const freshTokenPromise = fetch('/token/refresh', { method: 'POST', body: JSON.stringify(value) })
    .then((response) => {
      dispatch(loading(false));

      return response.json();
    })
    .then((t) => {
      dispatch(success(t));
      saveAppToken(t.token, t.refresh_token);
      return t.token ? Promise.resolve(t.token) : Promise.reject({
        message: 'could not refresh token'
      });
    })
    .catch((e) => {
      dispatch(loading(false));
      dispatch(error(e.message));
      dispatch(signOut());
      return Promise.resolve();
    });

  dispatch({
    type: 'REFRESHING_TOKEN',
    // we want to keep track of token promise in the state so that we don't try to refresh
    // the token again while refreshing is in process
    freshTokenPromise
  });

  return freshTokenPromise;
}

/* eslint-enable */
