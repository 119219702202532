import { fetch } from '../../utils/dataAccess';

export const error = (error) => {
  return { type: 'VOUCHERS_CONFIG_ERROR', error };
}

export const loading = (loading) => {
  return { type: 'VOUCHERS_CONFIG_LOADING', loading };
}

export const success = (retrieved) => {
  return { type: 'VOUCHERS_CONFIG_SUCCESS', retrieved };
}

export const retrieve = () => {
  return dispatch => {
    dispatch(loading(true));

    return fetch(`system_settings/show_coupon_option_on_checkout`, { method: 'get' })
      .then(response =>
        response
          .json()
          .then(retrieved => {
            dispatch(loading(false));
            dispatch(success(retrieved.data));
            dispatch(error(null));
          })
      )
      .catch(e => {
        dispatch(loading(false));
        dispatch(error(e.message));
        dispatch(success(false));
      });
  };
}

