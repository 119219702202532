import React from 'react';
import { useScreenshot, createFileName } from 'use-react-screenshot';
import html2PDF from 'jspdf-html2canvas';

import './Downloader.scss';

import ImageIcon from '../../Assets/Images/download-image.png';
import PdfIcon from '../../Assets/Images/download-pdf.png';
import WhatsappIcon from '../../Assets/Images/share-whatsapp.png';

const Downloader = (props) => {
  const { filename } = props;
  const refImage = props.image;
  const refPDF = props.pdf;
  const refWhatsapp = props.whatsapp;
  const refFormat = props.format;

  // eslint-disable-next-line no-unused-vars
  const [image, takeScreenShot] = useScreenshot({
    type: 'image/jpeg',
    quality: 1.0
  });

  const download = (thisImage, { name = filename, extension = 'jpg' } = {}) => {
    const a = document.createElement('a');
    a.href = thisImage;
    a.download = createFileName(extension, name);
    a.click();
  };

  const downloadImage = () => {
    takeScreenShot(refImage.current).then(download);
  };

  const downloadPdf = () => {
    html2PDF(refPDF, {
      jsPDF: {
        orientation: refPDF[0].clientWidth >= refPDF[0].clientHeight ? 'l' : 'p',
        format: refFormat
      },
      imageType: 'image/jpeg',
      output: `${filename}.pdf`,
    });
  };

  const shareWhatsapp = () => {
    window.open(refWhatsapp, '_blank');
    return null;
  };

  return (
    <div className="downloader">
      {refPDF && (
        <div className="option" onClick={() => downloadPdf()}>
          <img src={PdfIcon} alt="Unduh Pdf"/>
          PDF
        </div>
      )}
      {refImage && (
      <div className="option" onClick={() => downloadImage()}>
        <img src={ImageIcon} alt="Unduh Gambar"/>
        Gambar
      </div>
      )}
      {refWhatsapp && (
      <div className="option" onClick={() => shareWhatsapp()}>
        <img src={WhatsappIcon} alt="Bagikan Whatsapp"/>
        Bagikan
      </div>
      )}
    </div>
  );
};

export default Downloader;
