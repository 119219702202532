import { SubmissionError } from 'redux-form';
import { fetch } from '../../utils/dataAccess';
import { toast } from 'react-toastify';
// import ErrorToast from '../../components/ToastTexts/ErrorToast';

export function error(error) {
  return { type: 'CHILDREN_CREATE_ERROR', error };
}

export function loading(loading) {
  return { type: 'CHILDREN_CREATE_LOADING', loading };
}

export function success(created) {
  return { type: 'CHILDREN_CREATE_SUCCESS', created };
}

export function create(values, History, langData) {
  return dispatch => {
    dispatch(loading(true));

    return fetch('register_child', { method: 'post', body: JSON.stringify(values) })
      .then(response => {
        dispatch(loading(false));

        return response.json();
      })
      .then(retrieved => {
        History.push('/account');
        toast.success(langData.successAddChild);
        dispatch(success(retrieved));
      })
      .catch(e => {
        dispatch(loading(false));
        toast.error(e.toString());
        if (e instanceof SubmissionError) {
          dispatch(error(e.errors._error));
          throw e;
        }

        dispatch(error(e.message));
      });
  };
}

export function reset() {
  return dispatch => {
    dispatch(loading(false));
    dispatch(error(null));
  };
}
