export const APP_NAME = 'Titik Pintar';
export const APP_ENV = 'dev';
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const ENTRYPOINT = `${API_BASE_URL}/`;
export const LOGIN_URL = '/login';
export const IMAGE_BASE_URL = process.env.REACT_APP_IMAGE_BASE_URL;
export const LOGIN_LINK_URL = '/login_token_link';
export const LOGIN_LINK_URL_LIVE_CHAT = '/login_token_link_live_chat';
export const USER_URL = '/users';
export const OTP_RESEND = 60;
export const GTAG_ID = process.env.REACT_APP_GTAG_ID;
export const FIREBASE_API_KEY = process.env.REACT_APP_FIREBASE_API_KEY;
export const FIREBASE_AUTH_DOMAIN = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN;
export const FIREBASE_DATABASE_URL = process.env.REACT_APP_FIREBASE_DATABASE_URL;
export const FIREBASE_PROJECT_ID = process.env.REACT_APP_FIREBASE_PROJECT_ID;
export const FIREBASE_STORAGE_BUCKET = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET;
export const FIREBASE_MESSAGING_SENDER_ID = process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID;
export const FIREBASE_APP_ID = process.env.REACT_APP_FIREBASE_APP_ID;
export const FIREBASE_MEASUREMENT_ID = process.env.REACT_APP_FIREBASE_MEASUREMENT_ID;
export const ONESIGNAL_APP_ID = process.env.REACT_APP_ONESIGNAL_APP_ID;
export const ONESIGNAL_REST_API_KEY = process.env.REACT_APP_ONESIGNAL_REST_API_KEY;
export const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;
export const TEACHER_USERNAME = process.env.REACT_APP_TEACHER_USERNAME;
export const TEACHER_PASSWORD = process.env.REACT_APP_TEACHER_PASSWORD;
export const OTP_COUNTDOWN = 60;
export const WEBSOCKET_ROCKET_CHAT = process.env.REACT_APP_ROCKETCHAT_WEBSOCKET;
export const ROCKET_CHAT = process.env.REACT_APP_ROCKETCHAT_URL;
export const WORKING_HOURS_START = '11:00';
export const WORKING_HOURS_END = '18:00';
export const WORKING_DAY_END = 5; // its friday
export const WORKING_HOURS_TZ = 'Asia/Jakarta';
export const CAPTCHA_KEY = process.env.REACT_APP_CAPTCHA_KEY;
export const HASH_DECRYPT = process.env.REACT_APP_HASH_DECRYPT;
export const API_KEY = process.env.REACT_APP_API_KEY;

export const noop = () => {};

export const headerConfig = {
  headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
};

export const headerAuthorizedLDJsonConfig = {
  headers: {
    Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    'Content-Type': 'application/json',
  },
};

export const headerLDJsonConfig = {
  headers: {
    'Content-Type': 'application/json',
  },
};

export const headerImageConfig = (files) => ({
  headers: {
    Authorization: '',
    'Content-Type': files.type,
  },
});

export const headerBlob = {
  responseType: 'blob',
};
