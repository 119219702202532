import { fetch } from '../../utils/dataAccess';
// import { success as deleteSuccess } from './delete';

export function error(error) {
  return { type: 'PAYMENTS_LIST_ERROR', error };
}

export function loading(loading) {
  return { type: 'PAYMENTS_LIST_LOADING', loading };
}

export function success(retrieved) {
  return { type: 'PAYMENTS_LIST_SUCCESS', retrieved };
}

export function list(perPage, pageNo) {
  return dispatch => {
    dispatch(loading(true));
    dispatch(error(''));

    fetch(`parents/my_payment_history?monthly=true&per_page=${perPage}&page=${pageNo}`)
      .then(response =>
        response
          .json()
          .then(retrieved => {
            dispatch(loading(false));
            dispatch(success(retrieved.data));
          })
      )
      .catch(e => {
        dispatch(loading(false));
        dispatch(error(e.message));
      });
  };
}
