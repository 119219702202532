/* eslint-disable max-len */
import React, { createRef, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment-timezone';

import Downloader from '../../Components/Downloader/Downloader';
import { decodeBase64 } from '../../Utils/Helper/helpers';

import certificatePlaceholder from '../../Assets/Images/certificate-placeholder.png';

import weekly1 from '../../Assets/Images/CERTIFICATE-KELAS-PINTAR-MINGGUAN-1.png';
import weekly2 from '../../Assets/Images/CERTIFICATE-KELAS-PINTAR-MINGGUAN-2.png';
import weekly3 from '../../Assets/Images/CERTIFICATE-KELAS-PINTAR-MINGGUAN-3.png';
import league1 from '../../Assets/Images/CERTIFICATE-LIGA-ANAK-PINTAR-1.png';
import league2 from '../../Assets/Images/CERTIFICATE-LIGA-ANAK-PINTAR-2.png';
import league3 from '../../Assets/Images/CERTIFICATE-LIGA-ANAK-PINTAR-3.png';
import school0 from '../../Assets/Images/CERTIFICATE-KELAS-PINTAR-Peserta.png';
import school1 from '../../Assets/Images/CERTIFICATE-SEKOLAH-BULANAN-1.png';
import school2 from '../../Assets/Images/CERTIFICATE-SEKOLAH-BULANAN-2.png';
import school3 from '../../Assets/Images/CERTIFICATE-SEKOLAH-BULANAN-3.png';

import './CertificatePage.scss';

const CertificatePage = () => {
  const { code } = useParams();
  const [data, setData] = useState(false);

  useEffect(() => {
    if (!data) {
      setData(JSON.parse(decodeBase64(code)));
    }
  }, [data, code]);

  const certificates = createRef(null);

  const getCertificate = () => {
    if (data) {
      switch (data.type) {
        case 'weekly':
          switch (data.rank) {
            case 1:
              return weekly1;
            case 2:
              return weekly2;
            case 3:
              return weekly3;
            default:
              return weekly3;
          }
        case 'league':
          switch (data.rank) {
            case 1:
              return league1;
            case 2:
              return league2;
            case 3:
              return league3;
            default:
              return league3;
          }
        case 'period':
          switch (data.rank) {
            case 1:
              return school1;
            case 2:
              return school2;
            case 3:
              return school3;
            default:
              return school0;
          }
        default:
          return certificatePlaceholder;
      }
    }
    return certificatePlaceholder;
  };

  const getTitle = () => {
    if (data) {
      switch (data.type) {
        case 'weekly':
          switch (data.rank) {
            case 1:
              return 'SERTIFIKAT EVENT KOMPETISI KELAS PINTAR PEMENANG MINGGUAN PERTAMA';
            case 2:
              return 'SERTIFIKAT EVENT KOMPETISI KELAS PINTAR PEMENANG MINGGUAN KEDUA';
            case 3:
              return 'SERTIFIKAT EVENT KOMPETISI KELAS PINTAR PEMENANG MINGGUAN KETIGA';
            default:
              return 'SERTIFIKAT EVENT KOMPETISI KELAS PINTAR PEMENANG MINGGUAN KETIGA';
          }
        case 'league':
          switch (data.rank) {
            case 1:
              return 'SERTIFIKAT EVENT LIGA ANAK PINTAR PEMENANG PERTAMA';
            case 2:
              return 'SERTIFIKAT EVENT LIGA ANAK PINTAR PEMENANG KEDUA';
            case 3:
              return 'SERTIFIKAT EVENT LIGA ANAK PINTAR PEMENANG KETIGA';
            default:
              return 'SERTIFIKAT EVENT LIGA ANAK PINTAR PEMENANG KETIGA';
          }
        case 'period':
          switch (data.rank) {
            case 1:
              return 'SERTIFIKAT EVENT KOMPETISI SEKOLAN PERIODE PEMENANG PERTAMA';
            case 2:
              return 'SERTIFIKAT EVENT KOMPETISI SEKOLAN PERIODE PEMENANG KEDUA';
            case 3:
              return 'SERTIFIKAT EVENT KOMPETISI SEKOLAN PERIODE PEMENANG KETIGA';
            default:
              return 'SERTIFIKAT EVENT KOMPETISI SEKOLAN PERIODE PESERTA';
          }
        default:
          return certificatePlaceholder;
      }
    }
    return 'SERTIFIKAT EVENT KOMPETISI SEKOLAN PERIODE PESERTA';
  };

  return (
    <>
    {data && data.name && (
        <div className="centering">
            <div className="page certificate-page" ref={certificates} >
                <img src={getCertificate()} alt="Certificate Background" className="background"/>
                <div className="content">
                    <h1 className={`name ${data.type} ${data.type}${data.rank}`}>{data.name}</h1>
                    {data.period && data.type === 'weekly' ? <h4 className={`periode ${data.type}`}>Period : {moment(data.period.start).format('DD MMM YYYY')} - {moment(data.period.end).format('DD MMM YYYY')}</h4> : ''}
                </div>
            </div>
        </div>
    )}
    {data && (
        <Downloader
            filename={getTitle()}
            image={certificates}
            pdf={document.getElementsByClassName('page')}
            format='letter'
        />
    )}
    </>
  );
};

export default CertificatePage;
