/* eslint-disable array-callback-return */
/* eslint-disable no-use-before-define */
/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Navbar from '../../Components/Navbar/Navbar';
import Input from '../../Components/Input/Input';
import Email from '../../Assets/Images/email_black.png';
import PhoneNumber from '../../Assets/Images/phone_number.png';
import Loading from '../../Components/Loading/Loading';
import { sendEvent } from '../../Generated/actions/event/create';
import { APP_NAME } from '../../Constants/Constants';

import {
  retrieve as parentActionRetrieveToUpdate, update,
} from '../../Generated/actions/parent/update';

import { signUpOTP, verifySignUpOTP } from '../../Actions/Auth';

import './EditParent.scss';

const EditParent = () => {
  // States

  const History = useHistory();

  const dispatch = useDispatch();

  const parentUpdateSelector = useSelector((state) => state.parent.update);

  const location = useSelector((state) => state.router.location.pathname);
  const errorMessage = useSelector((state) => state.auth.errorMessage);

  const isLoading = useSelector((state) => state.parent.update.updateLoading);

  const language = useSelector((state) => state.language.change);
  const langData = language.lang ? language.lang.data : {};

  const [profileToEdit, setProfileToEdit] = useState({});

  const [isSaveDisabled, setIsSaveDisabled] = useState(true);

  const [stepEmail, setEmailStep] = useState('disabled');
  const [countdownEmail, setEmailCountdown] = useState();
  const [stepPhoneNumber, setPhoneNumberStep] = useState('disabled');
  const [countdownPhoneNumber, setPhoneNumberCountdown] = useState();

  // Effects
  useEffect(() => {
    document.title = `${langData.editParentHeader} - ${APP_NAME}`;
  });

  useEffect(() => {
    if (stepEmail.includes('verification') && countdownEmail > 0) {
      const intervalId = setInterval(() => {
        setEmailCountdown(countdownEmail - 1);
      }, 1000);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [countdownEmail, stepEmail]);

  useEffect(() => {
    if (stepPhoneNumber.includes('verification') && countdownPhoneNumber > 0) {
      const intervalId = setInterval(() => {
        setPhoneNumberCountdown(countdownPhoneNumber - 1);
      }, 1000);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [countdownPhoneNumber, stepPhoneNumber]);

  useEffect(() => {
    dispatch(
      sendEvent({
        activity_type: 'OPEN_SCREEN',
        title: 'open_screen',
        screen_name: 'EditEmailAndPhonenumber',
        is_admin: 'No',
        payload: {
          source: 'account_login',
        }
      })
    );
  }, [dispatch]);

  // If profile to update is not already present in redux
  // get from server
  useEffect(() => {
    if (!parentUpdateSelector.addToEdit) {
      const stringToSend = `users/${location.split('/').pop()}`;
      dispatch(parentActionRetrieveToUpdate(stringToSend));
    }
  }, [parentUpdateSelector, location, dispatch]);

  // Add profile to edit to local state
  useEffect(() => {
    if (parentUpdateSelector.addToEdit) {
      const a = parentUpdateSelector.addToEdit;
      const object = {
        full_name: '', // Because default first_name & last_name for parent is empty
        email: a.email,
        email_otp: '',
        phone_number: a.phone_number,
        phone_number_otp: '',
      };
      setProfileToEdit(object);
    }
  }, [parentUpdateSelector.addToEdit]);

  useEffect(() => {
    if (parentUpdateSelector.addToEdit) {
      if (parentUpdateSelector.addToEdit.email === '') {
        setEmailStep('start');
      }
      if (parentUpdateSelector.addToEdit.phone_number === '') {
        setPhoneNumberStep('start');
      }
    }
  }, [parentUpdateSelector.addToEdit]);

  // Functions

  const onChange = (name, value) => {
    if (isSaveDisabled) {
      setIsSaveDisabled(false);
    }
    const object = { ...profileToEdit, [name]: value };
    setProfileToEdit(object);
  };

  const onSendOTP = (name, value) => {
    if (name === 'email') {
      const data = {
        email: value,
      };
      dispatch(signUpOTP(data, setEmailStep, setEmailCountdown));
    } else {
      const data = {
        phone_number: value,
      };
      dispatch(signUpOTP(data, setPhoneNumberStep, setPhoneNumberCountdown));
    }
  };

  const onReset = (name) => {
    if (name === 'email') {
      setEmailStep('start');
      const object = { ...profileToEdit, email: '' };
      setProfileToEdit(object);
    } else {
      setPhoneNumberStep('start');
      const object = { ...profileToEdit, phone_number: '' };
      setProfileToEdit(object);
    }
  };

  const onVerifyOTP = (name, value) => {
    if (name === 'email') {
      const data = {
        email: profileToEdit.email,
        otp: value,
      };
      dispatch(verifySignUpOTP(data, History, false)).then((result) => {
        if (result) {
          const dataEdit = {
            email: profileToEdit.email,
            otp: value,
          };
          dispatch(update(`users/${parentUpdateSelector.addToEdit.id}`, dataEdit, History, langData));
        }
      });
    } else {
      const data = {
        phone_number: profileToEdit.phone_number,
        otp: value,
      };
      dispatch(verifySignUpOTP(data, History, false)).then((result) => {
        if (result) {
          const dataEdit = {
            new_phone_number: profileToEdit.phone_number,
            otp: value,
          };
          dispatch(update(`users/${parentUpdateSelector.addToEdit.id}`, dataEdit, History, langData));
        }
      });
    }
  };

  return (
    <div className='page-container' >
      {isLoading && <Loading />}
      <Navbar />
      <div className='body-content' >
        <div className='body-content-heading' >
          <i
            className='fal fa-arrow-left body-content-heading-back-arrow'
            onClick={() => History.goBack()}
          />
          {langData.editParentHeader}
        </div>

        <span className='edit-profile-input-label' >
          <img src={Email} alt="" className='edit-profile-input-label-img' />
          {langData.parentEmail}
        </span>

        <div className={`edit-profile-input ${stepEmail.includes('start') ? 'start' : 'verification'}`}>
          {stepEmail.includes('verification') ? (
          <Input
            placeholder={langData.paymentOTPField}
            errorMessage={errorMessage || ''}
            value={profileToEdit.email_otp}
            onChange={(e) => onChange('email_otp', e.target.value)}
          />
          ) : (
          <Input
            placeholder={langData.parentEmailPlaceholder}
            value={profileToEdit.email}
            disabled={!!stepEmail.includes('disabled')}
            onChange={(e) => onChange('email', e.target.value)}
            errorMessage={stepEmail.includes('disabled') ? errorMessage : ''}
            autoFocus={!!stepEmail.includes('start')}
            addInputClass={stepEmail.includes('disabled') ? 'disabled' : ''}
          />
          )}
          {stepEmail.includes('disabled') ? (
          <div>
            <button
            className={`verification ${stepEmail.includes('verification') ? 'start' : ''}`}
            onClick={() => onReset('email')}>
              Ganti Email
            </button>
          </div>
          ) : (
          <div>
            <button
            className={`verification ${stepEmail.includes('verification') ? 'start' : ''}`}
            onClick={() => onSendOTP('email', profileToEdit.email)}
            disabled={countdownEmail > 1}
            >
              {countdownEmail > 1 ? `${countdownEmail} ${langData.second}` : langData.paymentVerification}
            </button>
            {stepEmail.includes('verification') ? (
              <button className="verification"
               onClick={() => onVerifyOTP('email', profileToEdit.email_otp)}>
                Update
              </button>
            ) : ''}
          </div>
          )}
        </div>

        <span className='edit-profile-input-label' >
          <img src={PhoneNumber} alt="" className='edit-profile-input-label-img' />
          {langData.parentPhone}
        </span>

        <div className={`edit-profile-input ${stepPhoneNumber.includes('start') ? 'start' : 'verification'}`}>
          {stepPhoneNumber.includes('verification') ? (
          <Input
            placeholder={langData.paymentOTPField}
            errorMessage={errorMessage || ''}
            value={profileToEdit.phone_number_otp}
            onChange={(e) => onChange('phone_number_otp', e.target.value)}
          />
          ) : (
          <Input
            placeholder={langData.parentPhoneNumberPlaceholder}
            value={profileToEdit.phone_number}
            disabled={!!stepPhoneNumber.includes('disabled')}
            onChange={(e) => onChange('phone_number', e.target.value)}
            errorMessage={stepPhoneNumber.includes('disabled') ? errorMessage : ''}
            autoFocus={!!stepPhoneNumber.includes('start')}
            addInputClass={stepPhoneNumber.includes('disabled') ? 'disabled' : ''}
          />
          )}
          {stepPhoneNumber.includes('disabled') ? (
          <div>
            <button
            className={`verification ${stepPhoneNumber.includes('verification') ? 'start' : ''}`}
            onClick={() => onReset('phone_number')}>
              Ganti Nomor
            </button>
          </div>
          ) : (
          <div>
            <button
            className={`verification ${stepPhoneNumber.includes('verification') ? 'start' : ''}`}
            onClick={() => onSendOTP('phone_number', profileToEdit.phone_number)}
            disabled={countdownPhoneNumber > 1}
            >
              {countdownPhoneNumber > 1 ? `${countdownPhoneNumber} ${langData.second}` : langData.paymentVerification}
            </button>
            {stepPhoneNumber.includes('verification') ? (
              <button className="verification"
               onClick={() => onVerifyOTP('phone_number', profileToEdit.phone_number_otp)}>
                Update
              </button>
            ) : ''}
          </div>
          )}
        </div>

        {/* {renderSubmit()} */}
      </div>
    </div>
  );
};

export default EditParent;
